import SubmitIframe from './SubmitIframe';
import IpadContent from "../../assets/images/ipad2.png";


function FormsPreviewCardComponent({ data, isDevice, children }) {

    return (
        <div className='w-100 preview-pad position-relative'>
            <img className='w-100' src={IpadContent} />
            <div className='w-100 h-100' style={{ position: 'absolute', top: '0', padding: '4.5% 4% 4% 4.5%' }}>
                <div className='w-100 h-100 d-flex align-items-center justify-content-center' style={{ borderRadius: '1rem', overflow: 'hidden', position: 'relative' }}>
                    {children ? children : <SubmitIframe isDevice={isDevice} data={data} number={data && data.eventNumber ? data.eventNumber : ""} title={data && data.eventName ? data.eventName : ""} formLink={data && data.formLink ? data && data.formLink : ""} />}
                </div>
            </div>
        </div>
    );
}

export default FormsPreviewCardComponent;