import { Content } from 'antd/es/layout/layout'
import React from 'react'
import { Outlet } from 'react-router-dom'

const FormSubmissionLayout = (props) => {
    return (
        <div style={{ minHeight: "100dvh", display: 'flex', flexDirection: "column" }}>
            <div className="nav-header-form">
                <h3 className='form-title'>Forms</h3>
            </div>
            <Content className='container content-layout' style={{ flex: 1,  display: 'flex', flexDirection: "column" }}>
                <Outlet />
            </Content>
        </div>
    )
}

export default FormSubmissionLayout