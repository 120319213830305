import * as React from 'react';

const ArrowRight = ({width, height, stroke, opacity}) => {
	return (
		<svg width={width ? width : "0.875rem"} height={height ? height : "0.75rem"} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g opacity={opacity ? opacity : "0.9"}>
				<path d="M8 1L12.5757 5.57574C12.8101 5.81005 12.8101 6.18995 12.5757 6.42426L8 11" stroke={stroke ? stroke : "#152C5B"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M1 1L5.57574 5.57574C5.81005 5.81005 5.81005 6.18995 5.57574 6.42426L1 11" stroke={stroke ? stroke : "#152C5B"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			</g>
		</svg>


	);
}

export default ArrowRight;
