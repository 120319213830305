import React from 'react'
import { FaUserCheck } from "react-icons/fa6";

const LegalAgeIcon = (props) => {
    const { width, height } = props;
    return (
        <div className='d-flex align-items-center justify-content-center' style={{ width: width, height: height, backgroundColor: '#fccfcf', borderRadius: 100 }}>
            <FaUserCheck color='#d16f6f' size="25px"/>
        </div>
    )
}

export default LegalAgeIcon