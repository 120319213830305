import React, { useState, useEffect, useRef } from "react";
import CSLLogo from "../assets/images/csl_logo_4.png";
import "./styles/submissionForm.page.css";
import { Button, Form, Input, Radio, Skeleton, Space } from "antd";
import CountryModal from "../components/modal/CountryModal";
import API from "../utils/request";
import MessageModalMain from "../components/container/modal/modalMessage/messageModalMain";
import { EVENT_SUBMIT, EVENT_SCAN, EVENT_CHECK_SCAN } from "../config/urls";
import { useNavigate } from "react-router-dom";
import Expired from "./exipred/expired";
import * as Cookie from "../utils/cookie";
import OtpInput from "../components/input/otp-input";
import { getMobileOperatingSystem } from "../utils/getDeviceInfo";
import {DeviceUUID} from 'device-uuid'

const SubmissionFormPage = ({ isPreview, labeEventName, isDevice }) => {
  const [submissionForm] = Form.useForm();
  const [zipCode, setZipCode] = useState("");
  const [zipCodeError, setZipCodeError] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const [event, setEvent] = useState({
    eventName: "",
  });
  const navigate = useNavigate();
  const [size, setSize] = useState("large");
  const messageModalRef = useRef("rememberMe");
  const [expired, setExpired] = useState(false);
  const [preventSubmit, setPreventSubmit] = useState(false);
  const [preventSubmitMessage, setPreventSubmitMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentCountry, setCurrentCountry] = useState({
    code: 1,
    name: "United States",
  });

  const onFieldsChange = async () => {
    const hasErrors = submissionForm
      .getFieldsError()
      .filter(({ errors }) => errors.length);
    setFormError([...hasErrors]);
  };

  const handleFailed = (failedError) => {
    const checkZipCode = currentCountry.name === "Canada" ? 6 : 5;
    if (zipCode.length < checkZipCode) {
      setZipCodeError(true);
      return submissionForm.setFields([
        {
          name: "zipCode",
          errors: [`Zip Code Required`],
        },
      ]);
    }
  }

  const onFinish = async (formData) => {
    try {
      if (!preventSubmit) {
        const params = {
          id: urlParams.get("id"),
          shareToken: urlParams.get("shareToken"),
          firstName: formData.firstName,
          lastName: formData.lastName,
          legalAge: formData.legalAge,
          email: formData.email,
          phone: {
            countryCode: currentCountry.code,
            phoneNumber: formData.phoneNumber,
          },
          zipCode: formData.zipCode.slice(
            0,
            currentCountry.name === "Canada" ? 6 : 5
          ).toUpperCase(),
          agreement: formData.agreement,
        };
        setIsSubmitting(true);
        await API.post(EVENT_SUBMIT, params)
          .then((res) => {
            if (res.status === 1) {
              const uuid = urlParams.get("uuid");
              Cookie.deleteCookie(uuid);
              navigate("/success");
            } else {
              messageModalRef.current.showError(res.message);
            }
          })
          .catch((err) => {
            setIsSubmitting(false);
            messageModalRef.current.showError(err.message);
          });
      } else {
        messageModalRef.current.showError(preventSubmitMessage);
      }
    } catch (err) {
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkError = (name) => {
    return formError.filter((i) => i.name[0] === name).length > 0;
  };


  const requestAdd = async () => {
    var uuid = new DeviceUUID().get();
    let params = {
      "deviceId": uuid,
      "type": getMobileOperatingSystem(),
      "id": urlParams.get("id"),
      "formLink": urlParams.get("type")
    }
    return params
  }

  useEffect(() => {
    if (!isPreview) {
      if (urlParams.get("type") === "scan") {
        CheckURL(true);
      } else {
        CheckURL();
      }
    } else {
      if (isDevice !== "pad") {
        setSize("default");
      }
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    submissionForm.setFieldValue("zipCode", "");
    setZipCode("");
  }, [currentCountry]);

  const checkDuplicate =
    (name) =>
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue(name) !== value) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error("First name and last name should not be the same")
          );
        },
      });

  const emailRules = [
    {
      type: "email",
      message: "A valid email address is required",
    },
  ];


  const CheckEvent = async (eventId) => {
    API.post(EVENT_CHECK_SCAN, {
      id: eventId,
    })
      .then((res) => {
        if (res.status === 1) {
          setEvent({ ...res.data });
          setPreventSubmit(false);
        } else {
          setExpired(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setExpired(true);
        setLoading(false);
      });
  };

  const Scan = async (params, eventId) => {
    API.post(EVENT_SCAN, params)
      .then((res) => {
        if (res.status === 1) {
          const newUrl = `${window.location.pathname
            }?id=${eventId}`;
          window.history.replaceState({}, "", newUrl);
          CheckEvent(eventId);
        } else {
          setLoading(false);
          setExpired(true);
        }
      })
      .catch((err) => {
        setExpired(true);
        setLoading(false);
      });
  };
  
  const CheckURL = async (isScan) => {
    const eventId = urlParams.get("id");
    if (eventId) {
      if (isScan) {
        const params = await requestAdd();
        Scan(params, eventId);
      } else {
        CheckEvent(eventId);
      }
    } else {
      setPreventSubmit(true);
      setLoading(false);
      setPreventSubmitMessage("Invalid Token");
      messageModalRef.current.showError("Invalid Token");
    }
  };

  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    // Only allow digit characters
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text");
    if (!/^[0-9]*$/.test(pasteData)) {
      event.preventDefault();
    }
  };
  let title = urlParams.get("title")

  return expired === true ? (
    <Expired />
  ) : (
    <div className="submission-form-layout">
      <div className="form-header">
        <img alt="csl logo" className="csl-logo" src={CSLLogo} />
        {
          loading ? (
            <Skeleton.Input active size="small"/>
          ) : (
            <h3 className="form-title">
              {/* {title ? title : event.eventName } */}
              {isPreview && labeEventName
                ? labeEventName
                : event.eventName}
            </h3>
          )
        }
      </div>
      <Form
        form={submissionForm}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={handleFailed}
        onFieldsChange={onFieldsChange}
        layout="vertical"
        size={size}
        autoComplete={"off"}
        className="w-100"
        requiredMark="optional"
      >
        <Form.Item
          name="firstName"
          dependencies={["lastName"]}
          label={<LabelForm firstText="Q1:" secondText="First Name" />}
          rules={[
            {
              required: true,
              message: "First Name Required",
            },
            checkDuplicate("lastName"),
          ]}
          className="form-input-layout"
        >
          <Input placeholder="Please input your first name" />
        </Form.Item>
        <Form.Item
          name="lastName"
          dependencies={["firstName"]}
          label={<LabelForm firstText="Q2:" secondText="Last Name" />}
          rules={[
            {
              required: true,
              message: "Last Name Required",
            },
            checkDuplicate("firstName"),
          ]}
          className="form-input-layout"
        >
          <Input placeholder="Please input your last name" />
        </Form.Item>
        <Form.Item
          name="legalAge"
          label={
            <LabelForm
              firstText="Q3:"
              secondText="Are you 18 years or older?"
            />
          }
          rules={[{ required: true, message: "" }]}
          className="form-input-layout"
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={true}>
                <span
                  style={{ color: checkError("legalAge") ? "#ff4d4f" : "black" }}
                >
                  Yes
                </span>
              </Radio>
              <Radio value={false}>
                <span
                  style={{ color: checkError("legalAge") ? "#ff4d4f" : "black" }}
                >
                  No
                </span>
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="email"
          label={<LabelForm firstText="Q4:" secondText="Email Address" />}
          rules={[
            {
              required: true,
              message: "A valid email address is required",
            },
            ...emailRules,
          ]}
          className="form-input-layout"
        >
          <Input placeholder="example@gmail.com" />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label={<LabelForm firstText="Q5:" secondText="Phone Number" />}
          rules={[
            {
              required: true,
              message: "Phone number Required",
            },
            {
              pattern: new RegExp(/^[0-9]{9,10}$/),
              message:
                "Phone number should be 10 digits",
            },
          ]}
          onKeyPress={handleKeyPress}
          onPaste={handlePaste}
          className="form-input-layout"
        >
          <Space direction="vertical" size="middle" className="w-100">
            <Space.Compact style={{ width: '100%' }}>
              <Button>
                <CountryModal
                  size={size}
                  currentCountry={currentCountry}
                  setCurrentCountry={setCurrentCountry}
                  setZipCode={setZipCode}
                  zipCode={zipCode}
                />
              </Button>
              <Input
                placeholder="Please input your phone number"
                type="tel"
                className="custom-phonenumber"
                maxLength={10}
              />
            </Space.Compact>
          </Space>
        </Form.Item>
        <Form.Item
          name="zipCode"
          label={<LabelForm firstText="Q6:" secondText="Zip Code" />}
          rules={[
            {
              required: true,
              message: "",
              min: currentCountry.name === "Canada" ? 6 : 5,
              max: 6
            },
            { validator: async () => Promise.resolve() },
          ]}
          className="form-input-layout custom-zipcode"
        >
          <OtpInput
            value={zipCode}
            onChange={(value) => {
              setZipCodeError(false);
              setZipCode(value);
            }}
            length={currentCountry.name === "Canada" ? 6 : 5}
            type={currentCountry.name === "Canada" ? "text" : "number"}
            error={zipCodeError}
          />
        </Form.Item>
        <Form.Item
          name="agreement"
          label={
            <LabelForm
              firstText="Q7:"
              secondText="Yes, I would like to stay up to date on offers and promotions. I consent to receive recurrung automated marketing by text message and/or calls through non-automated and/or automated telephone or means. Consent is not a condition to donating plasma or receiving payment. Message or Data rates may apply."
            />
          }
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
          className="form-input-layout"
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={true}>
                <span
                  style={{ color: checkError("agreement") ? "#ff4d4f" : "black" }}
                >
                  I agree
                </span>
              </Radio>
              <Radio value={false}>
                <span
                  style={{ color: checkError("agreement") ? "#ff4d4f" : "black" }}
                >
                  I disagree
                </span>
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        {isPreview ? (
          <Form.Item>
            <div
              className="form-submission-div"
              type="primary"
            >
              Submit
            </div>
          </Form.Item>
        ) : (
          <Form.Item>
            <Button
              className="form-submission-btn"
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
            >
              Submit
            </Button>
          </Form.Item>
        )}
      </Form>
      <MessageModalMain textCentered ref={messageModalRef} />
    </div >
  );
};

const LabelForm = (props) => {
  const { firstText, secondText } = props;
  return (
    <label className="form-label">
      <Space className="d-flex flex-row align-items-start">
        <span>{firstText}</span>
        <div>
          {secondText}
          <span className="ms-1 text-danger">*</span>
        </div>
      </Space>
    </label>
  );
};

export default SubmissionFormPage;
