import React from 'react'
import "./inputField.css"
import { DatePicker } from 'antd'

import CalendarIcon from "../../../assets/svgicons/calendarIcon"
import moment from 'moment'
import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';

const InputDate = (props) => {
    const { max, min, label, require, value, onChange, error, status, invalid, id, placeholder, disabled, className, size } = props
    return (
        <div className="contain_input" style={{paddingLeft:"0px"}}>
            {
                label && (
                    <div className='d-flex flex-row align-items-center mb-2'>
                        <label className="form-label label_field">{label}</label>
                        {
                            require ? <span className='require'>*</span> : ""
                        }
                    </div>
                )
            }
            <DatePicker
                className={`pace-form-chart-birthday-css  ${className ? className : `${size ? size : 'input_field height-cus'}`}`}
                locale="en-US"
                size={size ? size : 'md'}
                status={status ? status : ""}
                id={id}
                icon={<CalendarIcon />}
                format="MM/DD/YYYY"
                onChange={(date) => {
                    onChange(date);
                }}
                placeholder={placeholder ? placeholder : `MM/DD/YYYY`}
                value={value ? dayjs(value, dateFormat) : null}
                disabled={disabled? disabled: false}
                minDate={min ? dayjs(min, dateFormat) : null}
                maxDate={max ? dayjs(max, dateFormat) : null}
            />
            {
                error && !value || invalid ? <div style={{ fontSize: '0.875em', marginTop: '0.25rem', color: '#FC1921' }}>{invalid ? invalid : error}</div> : ""
            }
        </div>
    )
}

export default InputDate