// check role
export const CenterDetail = (centerId, centerData) => {
    let findCenterId = centerData && centerData.find(it => it.locationID === centerId)
    if(findCenterId) {
        return `CSL Plasma, ${findCenterId.name}`
    }
    return centerId
}

export const CenterDetails = (centerId, centerData) => {
    let findCenterId = centerData && centerData.find(it => it.locationID === centerId)
    if(findCenterId) {
        findCenterId.centerName = `CSL Plasma, ${findCenterId.name}`
        return findCenterId
    }
    return centerId
}

export const HeaderCenterName = (centerId, centerData) => {
    let findCenterId = centerData && centerData.find(it => it.locationID === centerId);
    if(findCenterId) {
        return `CSL Plasma, ${findCenterId.name}`
    }
}

export const CenterObject = (centerId, centerData) => {
    let findCenterId = centerData && centerData.find(it => it.locationID === centerId)
    if (findCenterId) {
        return JSON.stringify(findCenterId)
    }
    return centerId
}