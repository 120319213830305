import { Table, message } from 'antd';
import qs from 'qs';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import moment from 'moment';
import Paginationcomponent from './Paginationcomponent';
import API from '../../utils/request'
import { EVENT_FORMSUBMISSION_SCAN_URL } from '../../config/urls';
import { CheckSortBy } from '../../utils/string';
import InputDate from '../container/inputField/inputDate';
import timeUtils from '../../utils/timeUtils';
import LoadingOverlay from '../container/loading/LoadingOverlay';
import { BsCheck, BsX } from 'react-icons/bs';
import { CgBorderStyleSolid } from 'react-icons/cg';
import BackRightBtn from '../../assets/svgicons/BackRightBtn';
import { Skeleton } from 'antd';
import { formatDateTimeSlasMDYA } from '../../utils/dateFormatUtils';


const TableComponent = forwardRef(({eventId, dataItems}, ref) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0)
  const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 10,
      total: 10
  })
  const [isCall, setIsCall] = useState(false)

  const [searchParams , setSearchParams] = useState({
    "email": "",
    "fromDate": "",
    "toDate": "",
    "assignCenter": "",
    "role": null, //["field_marketing", "center_staff"]
    "name": "",
    "sortField": "_id",
    "sort": "desc",
  })

  useEffect(() => {
    function checkHeader(data, isHeader) {
        const eventSubmitsHearder = [
            {
                "label": "First Name",
                "questionType": "text",
                "key": "first_name",
                "isRequired": true,
                "answers": []
            },
            {
                "label": "Last Name",
                "questionType": "text",
                "key": "last_name",
                "isRequired": true,
                "answers": []
            },
            {
                "label": "Email Address",
                "questionType": "text",
                "key": "email",
                "isRequired": true,
                "answers": []
            },
            {
                "label": "Phone Number",
                "questionType": "text",
                "key": "phone_number",
                "isRequired": true,
                "answers": []
            },
            {
                "label": "Zip Code",
                "questionType": "text",
                "key": "zip_code",
                "isRequired": true,
                "answers": []
            },
            {
                "label": "18 years or older",
                "questionType": "single-choice",
                "key": "legal_age",
                "isRequired": true,
                "answers": [
                    {
                        "answer": "Yes",
                        "result": "Pass",
                        "isanswer": true
                    },
                    {
                        "answer": "No",
                        "result": "Fail",
                        "actions": [],
                        "isanswer": false
                    }
                ]
            },
            {
                "label": "Yes, I would like to stay up to date on offers and promotions. I consent to receive recurrung automated marketing by text message and/or calls through non-automated and/or automated telephone or means. Consent is not a condition to donating plasma or receiving payment. Message or Data rates may apply.",
                "questionType": "single-choice",
                "key": "agreement",
                "isRequired": true,
                "answers": [
                    {
                        "answer": "I agree",
                        "result": "Pass",
                        "isanswer": true
                    },
                    {
                        "answer": "I disagree",
                        "result": "Fail",
                        "actions": [],
                        "isanswer": false
                    }
                ]
            },
        ]
     
        let newHeader = data && data.map(it => ({
            "label": it?.label,
            "questionType": it?.questionType,
            "key": it?.key,
            "isRequired": it?.key ? true : false,
            "answers": it?.answers ? it?.answers : []
        }))
        
        return isHeader ? newHeader : eventSubmitsHearder
    }
     

    if (dataItems) {
      const newHeader = checkHeader(dataItems.eventHeader, dataItems.eventHeader);

      const temp = [];
      newHeader.map((item) => {
        temp.push({
          title: item.key === "agreement" ? <span style={{textWrap: "nowrap"}}>Agree to the conditions</span> : <span>{item.label}</span>,
          width: 450,
          dataIndex: item.key,
          render: (_, record) => {
            if (!record || !record.eventSubmits) {
              return "";
            }

            const currentKey = record.eventSubmits.find(i => i.key === item.key);
            if (!currentKey) {
              return "";
            }

            if (!currentKey.isRequired && !currentKey.answers) {
              return "";
            }

            if (currentKey.questionType === "text") {
              return <div>
                {currentKey.answers.map(i => <div>{i}</div>)}
              </div>
            } else if (currentKey.questionType === "single-choice") {
              return currentKey.answers.map(i => i.isanswer ? <div>{i.answer}</div> : "")
            } else if (currentKey.questionType === "multi-choice") {
              return <div>
                {currentKey.answers.map(i => i.isanswer ? <div>{i.answer}</div> : "")}
              </div>
            }
          }
        });
      })
      if (temp.length > 0) {
        setColumns([...temp, ...[{
          title: 'Submission time',
          dataIndex: 'createdAt',
          sorter: true,
          render: (_, record) => formatDateTimeSlasMDYA(record.createdAt),
        }]]);
      }
    } 
  }, [dataItems])

  

  const [columns, setColumns] = useState([]);

  const getDataSubmissions = async () => {
    try {
      setLoading(true)
      API.post(`${EVENT_FORMSUBMISSION_SCAN_URL}/${eventId}`, {
        fromDate: searchParams.fromDate,
        toDate: searchParams.toDate,
        "sortField": searchParams.sortField ? searchParams.sortField : "_id",
        "sort": searchParams.sort ? searchParams.sort : "desc",
        "limit": pagination.pageSize,
        "page": pagination.current
      })
        .then((res) => {
          if (res.status === 200) {
            setData(res.data ? res.data : [])
            setTotal(res.totalCount ? res.totalCount : 0)
          }
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
        })
    } catch (error) {
      setLoading(false)
      message.error('Internal Server Error!')
    }
  }

  useEffect(() => {
    eventId && getDataSubmissions()
  }, [searchParams, eventId, JSON.stringify(pagination), isCall]);


  const handleTableChange = (pagination, filters, sorter) => {
    setSearchParams({
      ...searchParams, 
      sortField: sorter.field ? sorter.field : "_id",
      sort: sorter.order && CheckSortBy(sorter.order) ? CheckSortBy(sorter.order) : "desc",
    })
  };
  
  useImperativeHandle(ref, () => ({
    getDataSubmissions,
    data,
  }));

  return (
    <>
      <div className="w-100 h-100- mt-3 mb-3">
        <div className="row">
          <div className='col-5 col-sm-5 col-md-3 col-lg-3 mt-1'>
            <InputDate
              value={searchParams.fromDate}
              onChange={(date) => {
                setSearchParams({ ...searchParams, fromDate: date ? timeUtils.formatData(date) : "" })
              }}
              id="startDate"
              placeholder='Start Date'
              max={searchParams.toDate}
            />
          </div>
          <div className='m-0 p-0 div-10px'>
          <span className='ps-2 pe-2'><BackRightBtn opacity={"0.4"} width={"1rem"} height={"1rem"} /></span>
          </div>
          <div className='col-5 col-sm-5 col-md-3 col-lg-3 mt-1'>
            <InputDate
              value={searchParams.toDate}
              onChange={(date) => {
                setSearchParams({ ...searchParams, toDate: date ? timeUtils.formatData(date) : "" })
              }}
              id="endDate"
              placeholder='End Date'
              min={searchParams.fromDate}
            />
          </div>
        </div>
      </div>
    {
      loading ? <Skeleton.Button className='custom-skeleton-button' loading={loading} active={true} size={'large'} shape={'default'} block={true} /> :
      <>
      <Table
        scroll={{ x: 660 }}
        columns={columns}
        dataSource={data}
        pagination={false}
        onChange={handleTableChange}
      />
      <div className='w-100 mt-2'><Paginationcomponent total={total} pagination={pagination} setPagination={setPagination} /></div>
      </>
    }
    {/* {loading ? <LoadingOverlay /> : null} */}
</>
  );
});

export default TableComponent;
