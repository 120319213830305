import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx-js-style';
import { CenterDetail } from './checkCenterLocation';
import { formatDateSecondary } from './dateFormatUtils';
import { AiOutlineExport } from 'react-icons/ai';
import API from '../utils/request'
import { EVENT_FORMSUBMISSION_SCAN_URL } from '../config/urls';
import { message } from 'antd';
import moment from 'moment';

const ExportToExcel = ({ eventDetail, centerData, fileName }) => {

    const [formData, setFormData] = useState([]);

    useEffect(() => {
        if (eventDetail) {
            try {
                API.post(`${EVENT_FORMSUBMISSION_SCAN_URL}/${eventDetail.eventId}`, {"limit": "0"})
                    .then((res) => {
                        if (res.status === 200) {
                            setFormData(res.data ? res.data : [])
                        }
                    })
                    .catch((err) => {
                        message.error(err)
                    })
            } catch (error) {
                message.error('Internal Server Error!')
            }
        }
    }, [eventDetail])

    const exportToExcel = () => {
        const updatesLabel = [
            {
                old: "Yes, I would like to stay up to date on offers and promotions. I consent to receive recurrung automated marketing by text message and/or calls through non-automated and/or automated telephone or means. Consent is not a condition to donating plasma or receiving payment. Message or Data rates may apply.",
                new: "Agree to the conditions"
            }
        ];

        // Create the dynamic headers
        const dynamicHeaders = [
            ["Event Name:", `${eventDetail.eventName}`],
            ["Event Number:", `${eventDetail.eventNumber}`],
            ["Event Description:", `${eventDetail.eventDescription}`],
            ["Event Center:", `${eventDetail.eventCenter.reduce((accumulator, currentValue) => {
                return accumulator + CenterDetail(currentValue, centerData) + "\n";
            }, "")}`],
            ["Event date:", `${formatDateSecondary(eventDetail.startDate)} - ${formatDateSecondary(eventDetail.endDate)}`]
        ];

        let displayHeaders = [];

        // Get the headers dynamically from the first item
        const headers = formData[0].eventSubmits.map(submit => submit.label);
        headers.push("Submission time");
        updatesLabel.forEach((update, index) => {
            headers.forEach((label) => {
                if (label === update.old) {
                    label = update.new;
                }
                displayHeaders.push(label);
            })
        })

        // Transform JSON data to match the desired layout
        const transformedData = formData.map((item) => {
            const formData = item.eventSubmits.reduce((acc, field) => {
                acc[field.label] = field.answers[0];
                if (field.answers.length >= 2) {
                    let fetchAnswer = field.answers.find(answer => answer.isanswer);
                    acc[field.label] = fetchAnswer ? fetchAnswer.answer : "";
                } else {
                    acc[field.label] = field.answers[0];
                }
                return acc;
            }, {});

            formData["Submission time"] = moment(item.createdAt).format('MMMM D, YYYY h:mm A')//new Date(item.createdAt).toLocaleString();

            return formData;
        });

        // Convert the transformed data into an array of arrays for XLSX
        const wsData = [
            ...dynamicHeaders,
            [],
            displayHeaders,
            ...transformedData.map(row => headers.map(header => row[header]))
        ];

        // Create a new workbook and ws
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(wsData);

        // Define styles for header cells
        const headerCellStyle = {
            font: { bold: true },
            alignment: { vertical: 'middle', horizontal: 'center' },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        };

        const wrapStyle = { alignment: { wrapText: true } };

        // Apply styles to header cells
        displayHeaders.forEach((header, index) => {
            const cellAddress = XLSX.utils.encode_cell({ r: 6, c: index });
            if (!ws[cellAddress]) ws[cellAddress] = {};
            ws[cellAddress].s = headerCellStyle;
        });

        ws['B3']['s'] = wrapStyle;
        ws['B4']['s'] = wrapStyle;

        // Define column widths
        const wscols = new Array(displayHeaders.length).fill({ wch: 25 });
        ws['!cols'] = wscols;

        // Append ws to workbook
        XLSX.utils.book_append_sheet(wb, ws, "FormSubmission");

        // Write the workbook and save as an Excel file
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${eventDetail.eventName.replaceAll(" ", "_")}.xlsx`);
    };

    return (
        <div onClick={exportToExcel} className={`d-flex align-items-center ${formData.length > 0 ? "" : "disabledDiv"}`} style={{ color: "rgb(108, 117, 125)", cursor: "pointer", fontSize: "0.8rem" }}><AiOutlineExport /> <span className="ms-1">Export</span></div>
    );
};

export default ExportToExcel;