import { Outlet } from 'react-router-dom';
import PageNotAccess from '../../pages/not-access/PageNotAccess';
// import store from '../../stores';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { rootSaga, store, sagaMiddleware } from '../../modules/redux/sagas';
import { CheckRoleType } from '../../utils/checkRole';
let routeMtg = [
  "/user-management"
]

const GuardedRoutes = ({ accessingRight, or, and }) => {
  const user = useSelector((state) => state.authReducer.user);

  if (!accessingRight) {
    return <Outlet />;
  } else {
    if(routeMtg.includes(accessingRight)) {
      if(CheckRoleType(user && user.role)) {
        return <Outlet />;
      } else {
        return <PageNotAccess />;
      }
    } else {
      return <Outlet />;
    }
  }  
};

export const AccessRight = (accessingRight) => {
  const user = store.getState().authenticatedUser;
  return user ? user.rightList.includes(accessingRight) : false;
};

export default GuardedRoutes;
