import moment from "moment";
import 'moment/locale/es';

export const formatDate = date => {
    moment.locale('en')
    return moment(date).format('YYYY-MM-DD');
}
export const formatDateSecondary = date => {
    moment.locale('en')
    return moment(date).format('MM/DD/YYYY');
}
export const formatDateTimekkk = date => {
    moment.locale('en')
    return moment(date).format('YYYY-MM-DD  h:mm:ss');
}
export const formartDateMDY24hr = date => {
    moment.locale('en')
    return moment(date).format('MM/DD/YYYY HH:mm:ss');
}
export const formartDateMDY24hrNoSecond = date => {
    moment.locale('en')
    return moment(date).format('MM/DD/YYYY HH:mm');
}
export const formatDateTimes = date => {
    moment.locale('en')
    return moment(date).format('YYYY-MM-DD  h:mm a');
}
export const formatDateTimeSlas = date => {
    moment.locale('en')
    return moment(date).format('DD/MM/YYYY  h:mm a');
}
export const formatDateTimeSlasMDY = date => {
    moment.locale('en')
    return moment(date).format('MM/DD/YYYY  h:mm a');
}
export const formatDateTimeSlasMDYA = date => {
    moment.locale('en')
    return moment(date).format('MMMM D, YYYY h:mm A');
}
export const formatDateTimeCurent = (date, time) => {
    moment.locale('en')
    const today = moment(date).format('YYYY-MM-DD h:mm a')
    const todayStr = `${today}`
    let str = time ? todayStr.slice(-8) : todayStr
    let strNew = todayStr.replace(str, ` ${time}`)
    return moment(strNew).local("en")
}
export const formatDateTimeCurentNew = date => {
    moment.locale('en')
    return moment(date).format('h:mm a')
}

export const formatDateTimeCurentCalendar = (date, time) => {
    moment.locale('en')
    const today = moment(date).format('YYYY-MM-DD h:mm a')
    const todayStr = `${today}`
    let str = time ? todayStr.slice(-8) : todayStr
    let strNew = todayStr.replace(str, ` ${time}`)
    date =  moment(strNew).local("en")
    return moment(date).format('H:mm')
}

export const formatDateTimeSlasSpace = date => {
    moment.locale('en')
    return (
        <>
        <span>{moment(date).format('MM / DD / YYYY')}</span>&nbsp;&nbsp;&nbsp;
        <span>{moment(date).format('h:mm a')}</span>
        </>
    )
}

export const formatDateTimeSlasSpaceMMDDYYFullTime = date => {
    moment.locale('en')
    return (
        <>
        <span>{moment(date).format('MM / DD / YYYY')}</span>&nbsp;&nbsp;&nbsp;
        <span>{moment(date).format(' H: mm')}</span>
        </>
    )
}

export const formatDateTimeSlasSpaceMMDDYY = date => {
    moment.locale('en')
    return (
        <>
        <span>{moment(date).format('MM / DD / YYYY')}</span>&nbsp;&nbsp;&nbsp;
        <span>{moment(date).format('h:mm a')}</span>
        </>
    )
}

export const formatDateTimeSlasSpaceFullTime = date => {
    moment.locale('en')
    return (
        <>
        <span>{moment(date).format('YYYY-MM-DD')}</span>&nbsp;&nbsp;&nbsp;
        <span>{moment(date).format(' H: mm')}</span>
        </>
    )
}

export const formatDateTimeDay = date => {
    moment.locale('en')
    return moment(date).format('DD/MM/YYYY');
}
export const formatDateTime = date => {
    moment.locale('en')
    return moment(date).format('YYYY-MM-DD  H: mm');
}
export const formatTime = date => {
    moment.locale('en')
    return moment(date).format('H : mm a');
}

export const formatDateTimeAddTime = (date, minutes, seconds) => {
    moment.locale('en')
    var dateNew = moment(date)
        .add(seconds ? seconds : 0, 'seconds')
        .add(minutes ? minutes : 0, 'minutes')
    return moment(dateNew).format('YYYY-MM-DD  H: mm');
}


export const formattedDateText = (date, localelang) => {
    moment.locale('en')
    if(localelang === 'es') {
        moment.locale('es')
        // return (
        //     <>
        //     <span>{moment(date).format('DD')}</span>&nbsp;&nbsp;&nbsp;
        //     <span>{moment(date).format('MM')}</span>&nbsp;&nbsp;&nbsp;
        //     <span>{moment(date).format('YYYY')}</span>&nbsp;&nbsp;&nbsp;
        //     </>
        // )
        return date ? `${moment(date).format('D')} de ${moment(date).format('MMMM')} de ${moment(date).format('YYYY')}` : ""
    }
    return date ? moment(date).format('MMMM D, YYYY') : ""
};