// check role
export const CheckRoleType = (type) => {
    switch (type) {
        case `field_marketing`:
            return true
            break;
        case `center_staff`:
            return false
            break;
    
        default:
            return false
            break;
    }
}

export const CheckRoleTypeText = (type) => {
  switch (type) {
      case `field_marketing`:
          return {
              type: "Md.",
              role: "Field Marketing"
          }
          break;
      case `center_staff`:
          return {
              type: "Gm.",
              role: "Center Staff"
          }
          break;
  
      default:
          return {
              type: "Md.",
              role: "Field Marketing"
          }
          break;
  }
}