import { useState, useRef, useEffect } from "react";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import './style.css'
import { RiDownload2Line } from "react-icons/ri";
import PreviewDownloadCardComponent from "../card/PreviewDownloadCardComponent";


function QrCodeGenerator({urlQr, isShow, isNoFrom, isNodownload, className, isBorderQr, isCreate, data, isDownload, slideIndex}) {
  const [url, setUrl] = useState("");
  const [qrIsVisible, setQrIsVisible] = useState(true);

  const handleQrCodeGenerator = () => {
    if (!url) {
      return;
    }
    setQrIsVisible(true);
  };

  useEffect(() => {
    urlQr && setUrl(urlQr)
  }, [urlQr])

  const qrCodeRef = useRef(null);
  const qrCodeRef1 = useRef(null);

  const downloadQRCode = () => {
    htmlToImage
      // .toPng(qrCodeRef.current, { pixelRatio: 3 }) // Increase pixelRatio for higher resolution
      .toPng(qrCodeRef.current, { width: 1920, height: 1080 }) // Specify dimensions here
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "qr-code.png";
        link.click();
      })
      .catch(function (error) {
        console.error("Error generating QR code:", error);
      });
  };

  const downloadQRCode1 = () => {
    htmlToImage
    .toPng(qrCodeRef1.current, { pixelRatio: 10 })
      // .toPng(qrCodeRef1.current, { width: 1920, height: 1080 })
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "qr-code.png";
        link.click();
      })
      .catch(function (error) {
        console.error("Error generating QR code:", error);
      });
  };

  const printQRCode1 = () => {
    htmlToImage
      .toPng(qrCodeRef1.current)
      .then(function (dataUrl) {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(`<img src="${dataUrl}" onload="window.print();window.close();" />`);
        printWindow.document.close();
      })
      .catch(function (error) {
        console.error("Error generating QR code:", error);
      });
  };

  return (
    <div className="qrcode__container-">
      <div className={`qrcode__container--parent d-flex ${className}`} ref={qrCodeRef}>
        {isShow ? <div className="qrcode__input">
          <input
            type="text"
            placeholder="Enter a URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        
          <button onClick={handleQrCodeGenerator}>Generate QR Code</button>
            <button onClick={downloadQRCode}>Download QR</button>
        </div> : ""}

        {qrIsVisible && (
          <div className="qrcode__download" >
            <div className="text-center">
                <div 
                // ref={qrCodeRef1} 
                className={`qrcode__image ${isBorderQr}`} >
                    {url && <QRCode className="w-100 h-100" ref={qrCodeRef1}  value={`${url}&type=scan`} />}
                </div>
                {isNodownload ? '' : <button style={{width: "10rem"}} onClick={downloadQRCode1}><span className="me-1"><RiDownload2Line /></span><span>Download QR</span></button>}
            </div>
          </div>
        )}
        {isNoFrom ? '' : <div className={`text-start ${isCreate ? "w-full-120-03" : "w-100"}`}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
        >
            <a target="_blank" href={url}>{url.slice(0, 168)}...</a>
          <div className="w-100 d-flex">
            <div style={{ width: "10rem" }}>
              {isDownload && data ? <PreviewDownloadCardComponent codeLang={'en'} slideIndex={slideIndex} data={data} /> : ""}
            </div>
            <div style={{ width: "10rem" }}>
              {isDownload && data ? <PreviewDownloadCardComponent codeLang={'es'} slideIndex={slideIndex} data={data} /> : ""}
            </div>
          </div>
        </div>}

      </div>
    </div>
  );
}

export default QrCodeGenerator;