import { Carousel, Radio } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

const CarouselComponent = ({isShow, data, handleCurrentSlide, slideIndex, isText, width, height}) => {
    const contentStyle = {
        height: "100%",//'7rem',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: 'rgb(222 226 230 / 45%)'
    };
    const [dotPosition, setDotPosition] = useState('right');
    const carouselRef = useRef(null);
    const [activeSlide, setActiveSlide] = useState(0)

    const handlePositionChange = ({ target: { value } }) => {
        setDotPosition(value);
    };

    useEffect(() => {
        if (slideIndex === 0 || slideIndex > 1) {
            setActiveSlide(slideIndex);
        }
    }, [slideIndex]);
    
    const onChange = (currentSlide) => {
        setActiveSlide(currentSlide)
        handleCurrentSlide && handleCurrentSlide(currentSlide)
    };

    let isshow = false
    let isshowImg = true

    const handleImage = (currentSlide) => {
        if(data && data.length > currentSlide + 1) {
            setActiveSlide(currentSlide + 1)
            handleCurrentSlide && handleCurrentSlide(currentSlide + 1)
        } else {
            setActiveSlide(0)
            handleCurrentSlide && handleCurrentSlide(0)
        }
    }
    
    return (
        <>
            {isShow ? <Radio.Group
                onChange={handlePositionChange}
                value={dotPosition}
                style={{
                    marginBottom: 8,
                }}
            >
                <Radio.Button value="top">Top</Radio.Button>
                <Radio.Button value="bottom">Bottom</Radio.Button>
                <Radio.Button value="left">Left</Radio.Button>
                <Radio.Button value="right">Right</Radio.Button>
            </Radio.Group> : ""}
            {isshowImg && data && data.length > 0 ? <div className='w-100 h-100'>
                <img onClick={() => handleImage(activeSlide)} className='csl-logo' style={{ height: height ? height : "275px", width: width ? width : "247px", cursor: "pointer", objectFit: "contain" }} title='Click to change image' src={data[activeSlide].url} alt={data[activeSlide].name} />
                {/* {data && data.map((it, i) => (<div><img className='csl-logo' style={{ height: "3rem", marginLeft: "2.3rem" }} src={it.url} alt={it.name} /></div>))} */}
            </div> : ""}
            {isshow ? <Carousel 
            afterChange={onChange}  
            initialSlide={activeSlide}
            dotPosition={dotPosition}
            ref={carouselRef}
            // draggable
            >
                {
                    data && data.map((it, i) => (<div>
                        <div style={contentStyle}><img style={{height: "100%", width: "100%", minHeight: "247px", minWidth: "247px", objectFit: "contain"}} src={it.url} alt={it.name} /></div>
                    </div>))
                }
            </Carousel> : ''}
        </>
    );
};
export default CarouselComponent;