import * as React from "react";

export class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { reportResult } = this.props;

    return (
      <div className="relativeCSS">
        <style type="text/css" media="print">
            {'@media print { body { -webkit-print-color-adjust: exact; } @page { size: A4; margin: 10mm !important; }}'}
        </style>
        <div className="flash" />
        {reportResult}
      </div>
    );
  }
}

export const FunctionalComponentToPrint = React.forwardRef((ref) => {
  return <ComponentToPrint ref={ref} />;
});
