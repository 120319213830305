import { Pagination } from 'antd';
import React, { useState } from 'react';
const Paginationcomponent = ({
    iSshowTotal, setIsShowTotal, size, 
    pagination, setPagination,
     showSizeChanger, total
}) => {
    // const [pagination, setPagination] = useState({
    //     current: 1,
    //     pageSize: 10,
    //     total: 10
    // })

    const onChange = (current, pageSize) => {
      setPagination({...pagination,
        current: current,
        pageSize: pageSize
      })
    };

    return <>
        <div className='w-100 mt-1 text-end'>
            <Pagination
                size={size ? size : "md"}
                total={total ? total : 0}
                current={pagination && pagination.current}
                pageSize={pagination && pagination.pageSize}
                onChange={onChange}
                showSizeChanger={total  ? true : false}
                showTotal={iSshowTotal ? (total, range) => `${range[0]}-${range[1]} of ${total} items` : false}
            />
        </div>
  </>
}
export default Paginationcomponent;