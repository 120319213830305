import { Image, message, Spin, Upload } from 'antd';
import React, { useRef, useState } from 'react';
import { MdOutlineFileUpload } from 'react-icons/md';
import "./style.css"
import { UPLOAD_FILE } from '../../../config/urls';
import MessageModalMain from '../modal/modalMessage/messageModalMain';
import { FaEye } from 'react-icons/fa';
import CloseBtn from '../../../assets/svgicons/CloseBtn';
import { formatFileSize } from '../../../utils/string';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { API_ENDPOINT } from '../../../config/api';
import { sliceURL } from '../../../utils/sliceURL';
import API from '../../../utils/request'

const { Dragger } = Upload;

const UploadComponent = ({
    uploadFile, setUploadFile, className, classNameMain
}) => {
    const messageModalRef = useRef('rememberMe');
    const tokenR = useSelector((state) => state.authReducer.token);

    // File validation function
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt5M = file.size / 1024 / 1024 < 5;

        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            return true;
        }

        if (!isLt5M) {
            message.error('Image must be smaller than 5MB!');
            return true;
        }

        return false;
    };

    const [visible, setVisible] = useState(false);
    const [scaleStep, setScaleStep] = useState(1);
    const [urlShow, setUrlShow] = useState("");
    const [newContentLoading, setNewContentLoading] = useState(false)
    // const [uploadFile, setUploadFile] = useState([
    //     {
    //         name: "event gift picture 01.png",
    //         url: "https://pacsystem.s3.amazonaws.com/public-img/17177297207831717729720477_MTJV3.jpeg",
    //         type: "png",
    //         "size_type": "bytes",
    //         "size_img": 19383,
    //         "size": 19383,
    //     }
    // ])
    const [fileSupport, setFileSupport] = useState({
        contentDesignAsset: "Supported file types: i. .doc, .pdf, .xls, .ppt, images (.jpg/.png)",
        uploadFile: "Supported file types: i. .doc, .pdf, .xls, .ppt, images (.jpg/.png)"
    })
    const [uploadNull, setUploadNull] = useState("")
    const [existingContentFile, setExistingContentFile] = useState([])


    const handleUploadFiles = (fileName, filebase64, type, fileOrigin) => {

        setNewContentLoading(true)

        const FormData = require('form-data');
        let data = new FormData();
        data.append('file', fileOrigin);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${API_ENDPOINT}${UPLOAD_FILE}`,
            headers: {
                'Authorization': tokenR,
            },
            data: data
        };

        axios.request(config)
            .then((response) => response.data)
            .then(res => {
                setNewContentLoading(false)

                if (res.status === 1 && res.data) {
                    let data = res.data
                    
                    setUploadFile([...uploadFile,
                        {
                            "url": data.url,
                            "size_type": data.ursize_typel,
                            "size_img": data.size_img,
                            size: data.size_img,
                            "name": fileName
                        }
                    ])
                    setUploadNull('')
                } else if (res.result === false) {
                    messageModalRef.current.showWarning(res?.message);
                }
                setNewContentLoading(false)

            })
            .catch((error) => {
                console.log(error);
                setNewContentLoading(false)
            });
 
    }

    const handleUploadFile = (fileName, filebase64, type, fileOrigin) => {
        setNewContentLoading(true)
        var param = {
            "filename": fileName,
            "fileAsset": filebase64
        }
        API.post(UPLOAD_FILE, param)
            .then((res) => {
                setNewContentLoading(false)
                if (res.result) {
                    setUploadFile([...uploadFile,
                    {
                        "url": res.url,
                        "size_type": fileOrigin.ursize_typel,
                        "size_img": fileOrigin.size,
                        size: fileOrigin.size,
                        "name": fileName
                    }
                    ])
                    setUploadNull('')
                } else if (res.result === false) {
                    messageModalRef.current.showWarning("Failed!");
                }
            })
            .catch((err) => {
                setNewContentLoading(false)
                console.log(err);
            })
    }

    const toBase64 = (file, type, id, fileOrigin) => new Promise((resolve, reject) => {
        const fileName = file.name;
        document.getElementById(id).innerHTML = fileName;
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');

            handleUploadFile(fileName, base64String, type, fileOrigin);
        };
        reader.readAsDataURL(file);
    });

    const handleFileChange = async (event, type) => {
        const { value, id } = event.target;
        if (id === 'uploadFile') {
            if (existingContentFile.find(element => element === value)) {
                return false
            } else {
                fileSupport["uploadFile"] = value
            }
            existingContentFile.push(value)
            setExistingContentFile([...existingContentFile])
        }
        setFileSupport({ ...fileSupport })
        const file = event.target.files[0];

        if(beforeUpload(file)) {
            return false
        }
        await toBase64(file, type, id, file)
    }

    return (
        <div className='w-100'>
            <MessageModalMain textCentered ref={messageModalRef} />
            <div className={`w-100 upload-cus ${classNameMain}`}>
                <div className='row'>
                    <div className={`${uploadFile.length > 0 ? "col-sm-12 col-md-6 col-lg-6 pe-0" : "col-sm-12 col-md-12 col-lg-12"}`}>
                        <div className="d-flex flex-column">
                            <Spin size="large" tip="Loading..." spinning={newContentLoading}>
                                <div className={`upload-file-body ${className}`} style={{ cursor: "pointer" }}>
                                    <div className="d-flex- flex-row  text-centeralign-items-center justify-content-between">
                                        <div className="d-flex- text-center flex-row align-items-center mb-2">
                                            <button style={{ width: "8rem", fontSize: "0.9rem" }} className='btn-download'>
                                                <span className="me-1"><MdOutlineFileUpload /></span><span>Add images</span>
                                            </button>
                                        </div>
                                        <input id="uploadFile" style={{ cursor: "pointer" }} type="file" value={uploadNull} onChange={(e) => handleFileChange(e, 'uploadFile')} multiple placeholder="CSLPLASMA.pdf" />
                                        <div className="d-flex flex-column">
                                            <p className="size14600 mb-0 text-center" style={{ color: '#8B8B8B' }}>
                                                Support png, jpg. Maximum of three uploads. Maximun individual file size 5MB.
                                                <br />
                                                <span>Optimal resolution is 660 × 800</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Spin>
                        </div>
                    </div>
                    {uploadFile.length > 0 ? <div className='col-sm-12 col-md-6 col-lg-6'>
                        <div className="d-flex flex-column">
                            {
                                uploadFile.length > 0 ? uploadFile.map((item, index) => (
                                    <div className="d-flex mt-2 flex-row align-items-center justify-content-between w-100" style={{ position: 'relative', zIndex: '10', width: "90%" }}>
                                        
                                        <div className='d-flex w-100'>
                                            <div className='me-2 div-img-main' style={{ position: "relative", width: "3rem", height: "3rem", cursor: "pointer" }}>
                                                <img className='csl-logo' style={{ width: "3rem", borderRadius: "5px", height: "3rem", objectFit: "contain" }} src={item.url} alt={item.name} />
                                                <div className='img-hover' style={{ width: "3rem", borderRadius: "5px" }} >
                                                    <span
                                                        onClick={() => {
                                                            setUrlShow(item.url)
                                                            setVisible(true);
                                                        }}
                                                        style={{ cursor: "pointer" }}><FaEye /></span>
                                                </div>
                                            </div>
                                            <div style={{ width:'100%', overflow: "hidden", textWrap: "nowrap", textOverflow: "ellipsis", paddingRight: "10px" }}>
                                            <a target="_blank" href={item.url} className="mb-0 design-upload">
                                                {/* <span>{item.name ? item.name : `Name ${index}`}</span> */}
                                                <span>{item.url ? sliceURL(item.url) && sliceURL(item.url).length > 10 ? sliceURL(item.url).slice(20) : sliceURL(item.url) : ""}</span>
                                            </a>
                                            <br/><span style={{fontSize: "11px", opacity: "0.4"}}>{item.size ? formatFileSize(item.size) : `0 MB`} </span>
                                            </div>
                                        </div>
                                        <div style={{ position: "absolute", right: 0,cursor: "pointer" }} className="transparent-button">
                                            <span
                                                onClick={() => {
                                                    uploadFile.splice(index, 1)
                                                    setUploadFile([...uploadFile])
                                                    existingContentFile.splice(index, 1)
                                                    setExistingContentFile([...existingContentFile])
                                                    setUploadNull("")
                                                }}
                                                style={{ cursor: "pointer" }}><CloseBtn /></span>
                                        </div>
                                    </div>
                                )) : ""
                            }
                        </div>
                    </div> : ''}
                </div>
            </div>

            <Image
                width={200}
                style={{
                    display: 'none',
                }}
                src={urlShow}
                preview={{
                    visible,
                    scaleStep,
                    src: urlShow,
                    onVisibleChange: (value) => {
                        setVisible(value);
                        setUrlShow("")
                    },
                }}
            />

        </div>
    );
};

export default UploadComponent;