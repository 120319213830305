import { all } from 'redux-saga/effects';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducers from '../../../reducers';
import { fetchAfterExpiryTimeWatcher } from './ExTime.saga'
function* rootSaga() {
  yield all([
    fetchAfterExpiryTimeWatcher(),
  ]);
}

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducers, applyMiddleware(sagaMiddleware));

export { sagaMiddleware, store, rootSaga };
