import React from "react";
import PropTypes from "prop-types";
import { message } from "antd";

const NavItemNavigation = ({
  setCurrentKey,
  currentKey,
  steps,
  whiteSpace,
  marginRight,
  setEditpage,
  messageModalRef,
  marginLeft
}) => {
  return (
    <>
      <div className="d-flex flex-row align-items-center w-100" style={{}}>
        {steps.map((item, index) => (
          <div
            key={index}
            className="navigate_item"
            onClick={() => {
              item.key === "Survey" ?  messageModalRef.current.showWarningConfirmsAutoClose(`comming soon`, () => { }, "", true): setCurrentKey(item.key);
              setEditpage && setEditpage("")
            }}
            onKeyDown={() => setCurrentKey(item.key)}
            style={{
              color: currentKey === item.key ? "#152C5B" : "#152C5B",
              opacity: currentKey === item.key ? 1 : 0.4,
              borderBottom:
                currentKey === item.key
                  ? "0.25rem solid #0075FF"
                  : "0.25rem solid #F2F3F9",
              whiteSpace: `${whiteSpace}`,
              marginRight: `${marginRight}`,
              marginLeft: `${marginLeft}`,
              cursor: "pointer",
              fontSize:'1rem'
            }}
          >
            {item.name}
          </div>
        ))}
      </div>
      <div className="navigate_line" />
    </>
  );
};

NavItemNavigation.propTypes = {
  setCurrentKey: PropTypes.func.isRequired,
  currentKey: PropTypes.string.isRequired,
  // setEditpage: PropTypes.func.isRequired,
  // editpage: PropTypes.string.isRequired,
};

export default NavItemNavigation;
