import React from "react";

export default function IconScan({width, height, fill, isNoclipPath}) {
  return (
    <svg
      width={width ?? "62"}
      height={height ?? "62"}
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="31" cy="31" r="31" fill={fill ? fill : "#EBF3F5"} />
      <g clip-path="url(#clip0_912_560)">
        <path
          d="M17.3636 26.9091C16.5454 26.9091 16 26.3636 16 25.5454V20.0909C16 17.9091 17.9091 16 20.0909 16H25.5454C26.3636 16 26.9091 16.5454 26.9091 17.3636C26.9091 18.1818 26.3636 18.7273 25.5454 18.7273H20.0909C19.2727 18.7273 18.7273 19.2727 18.7273 20.0909V25.5454C18.7273 26.3636 18.1818 26.9091 17.3636 26.9091ZM25.5454 46H20.0909C17.9091 46 16 44.0909 16 41.9091V36.4545C16 35.6364 16.5454 35.0909 17.3636 35.0909C18.1818 35.0909 18.7272 35.6364 18.7272 36.4545V41.9091C18.7272 42.7272 19.2727 43.2727 20.0909 43.2727H25.5454C26.3636 43.2727 26.9091 43.8182 26.9091 44.6364C26.9091 45.4546 26.3636 46 25.5454 46ZM41.9091 46H36.4545C35.6364 46 35.0909 45.4546 35.0909 44.6364C35.0909 43.8182 35.6364 43.2727 36.4545 43.2727H41.9091C42.7272 43.2727 43.2727 42.7272 43.2727 41.9091V36.4545C43.2727 35.6364 43.8182 35.0909 44.6364 35.0909C45.4546 35.0909 46 35.6364 46 36.4545V41.9091C46 44.0909 44.0909 46 41.9091 46ZM44.6364 26.9091C43.8182 26.9091 43.2727 26.3636 43.2727 25.5454V20.0909C43.2727 19.2727 42.7272 18.7273 41.9091 18.7273H36.4545C35.6364 18.7273 35.0909 18.1818 35.0909 17.3636C35.0909 16.5454 35.6364 16 36.4545 16H41.9091C44.0909 16 46 17.9091 46 20.0909V25.5454C46 26.3636 45.4546 26.9091 44.6364 26.9091ZM44.6364 32.3636H17.3636C16.5454 32.3636 16 31.8182 16 31C16 30.1818 16.5454 29.6364 17.3636 29.6364H44.6364C45.4546 29.6364 46 30.1818 46 31C46 31.8182 45.4546 32.3636 44.6364 32.3636Z"
          fill="#AFCED7"
        />
      </g>
      {!isNoclipPath ? <defs>
        <clipPath id="clip0_912_560">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(16 16)"
          />
        </clipPath>
      </defs> : ""}
    </svg>
  );
}
