import moment from "moment/moment";

let timeOffset = null;


const nowTime = () => {
  let nTime = new Date();
  let sTime = new Date(nTime.getTime() + timeOffset);
  return sTime;
};
//"Thu May 11 2023 00:00:00 GMT+0800 (中国标准时间)" 转化为 "2023-05-11"
const formatData = (data) => {
  if(!data) {
    return ""
  }
  const date = new Date(data);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};
export default {
  nowTime,
  formatData
};
