import { Outlet, useLocation } from 'react-router-dom';
import './App.css';
import CslLayout from './components/layouts/layout';
import { Provider, connect, useDispatch } from 'react-redux';
import * as Storage from './utils/storage';

import {rootSaga, store, sagaMiddleware} from './modules/redux/sagas';
import { createContext, useEffect } from 'react';
import { storageKeys } from './constants/storage';
import { LOGIN_SUCCESS } from './modules/auth/constants';
import { withTranslation } from 'react-i18next';
sagaMiddleware.run(rootSaga);
export const UserContext = createContext(null);

function App({user, token}) {
  const dispatch = useDispatch();

  let location = useLocation();

  useEffect(() => {
    const tokens = Storage.getString(storageKeys.TOKEN);
    const users = Storage.getJson(storageKeys.USER);

    if (users && tokens) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token: tokens,
          user: users
        }
      });
    }
  }, [dispatch, location]);
  
  return (
      <UserContext.Provider value={{ user, token }}>
      <div>
        <CslLayout>
          <Outlet />
        </CslLayout>
      </div>
      </UserContext.Provider>
  );
}

const mapDispatchToProps = dispatch => ({ dispatch })

const mapStateToProps = state => ({
    user: state.authReducer.user,
    token: state.authReducer.token
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App));

