import { Button, Card } from "react-bootstrap";
import NavItemNavigation from "../../components/navigation/NavItemNavigation";
import React, { useEffect, useRef, useState } from "react";
import CardComponent from "../../components/card/CardComponent";
import { useSelector } from "react-redux";
import InputDate from "../../components/container/inputField/inputDate";
import timeUtils from "../../utils/timeUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckRoleType } from "../../utils/checkRole";
import API from '../../utils/request'
import { API_ENDPOINT_LOCATION } from "../../config/api";
import { Input, message } from "antd";
import { EVENT_LIST_URL } from "../../config/urls";
import Paginationcomponent from "../../components/table/Paginationcomponent";
import LoadingOverlay from "../../components/container/loading/LoadingOverlay";
import { CiSearch } from "react-icons/ci";
import { CenterDetail, CenterDetails, CenterObject } from "../../utils/checkCenterLocation";
import { storageKeys } from "../../constants/storage";
import * as Storage from '../../utils/storage';
import MessageModalMain from "../../components/container/modal/modalMessage/messageModalMain";
import SelectMultiCustom from "../../components/select/SelectMultiCustom";

const EventPage = () => {
    const [currentPositionKey, setCurrentPositionKey] = React.useState("active");
    const positionSteps = [
        { name: "Current", key: "active" },
        { name: "History", key: "expired" },
    ];
    const [data ,setData] = useState([])
    const navigate = useNavigate();
    const user = useSelector((state) => state.authReducer.user);
    var users = Storage.getJson(storageKeys.USER);
    let userUse = user ? user : users
    const location = useLocation()
    const [centerId, setCenterId] = useState([]);
    const [loading, setLoading] = useState(false);
    const messageModalRef = useRef('rememberMe');
    const [total, setTotal] = useState(0)
    const [isCall, setIsCall] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10
    })
    const [eventName, setEventName] = useState("")
    const [searchParams, setSearchParams] = useState({
        "eventName": "",
        "startDate": "",
        "endDate": "",
        "status": "active", //["active", "inactive", "deleted", "expired"]
        "centerId": "",
        "sortField": "_id",
        "sort": "desc",
    })
    const [centerData, setCenterData] = useState([])
    
    useEffect(() => {
        if (centerData.length > 0) {
            if (userUse && userUse?.role === "center_staff") {
                setCenterId([CenterObject(userUse?.centerId, centerData)])
            } else {
                if (location && location.state && location.state.centerId) {
                    setCenterId([CenterObject(location.state.centerId, centerData)])
                } else {
                    setCenterId([]);
                }
            }
        }
    }, [user, userUse, centerData])


    const getDataCenter = async () => {
        setLoading(true)
        try {
            API.post(API_ENDPOINT_LOCATION, {
                "serviceName": "location",
                "serviceType": "getSavableLocations"
            })
                .then((res) => {
                    if (res.status === 200) {
                        setCenterData(res.locations ? res.locations.map(it => ({
                            ...it,
                            label: `CSL Plasma, ${it.name}`,
                            value: it.locationID
                        })) : [])
                    }
                })
                .catch((err) => {
                })
        } catch (error) {
        }
    }
    useEffect(() => {
        getDataCenter()
    }, []);


  const getDataEventList = async () => {
    try {
      setLoading(true)
      API.post(EVENT_LIST_URL, {
        "searchParams": {
          ...searchParams,
          "eventName": eventName ? eventName.trim() : "",
          "status": currentPositionKey,
          "appliedCenter": centerId.map((i) => JSON.parse(i).locationID),
        },
        "sortField": searchParams.sortField ? searchParams.sortField : "_id",
        "sort": searchParams.sort ? searchParams.sort : "desc",
        "limit": pagination.pageSize,
        "page": pagination.current
      })
        .then((res) => {
          if (res.status === 200) {
            setData(res.data ? res.data.map(it => ({
                ...it,
                scanCount: it.scannedCount ? it.scannedCount : 0,
                submissionCount: it.submissionCount ? it.submissionCount : 0,
                // eventName: "Event Name 101",
                eventCenter: it.centerId,
                startData: it.startDate ? it.startDate : '',
                endData: it.endDate ? it.endDate : '',
            })) : [])
            setTotal(res.totalCount ? res.totalCount : 0)
          }
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
        })
    } catch (error) {
      setLoading(false)
      message.error('Internal Server Error!')
    }
  }
  useEffect(() => {
    if (centerData.length > 0) {
        if(userUse && userUse?.role === "center_staff" ? userUse?.centerId : null) {
            getDataEventList()
        } else if(userUse && userUse?.role === "field_marketing") {
            getDataEventList()
        }
    }
  }, [centerId, currentPositionKey, searchParams, JSON.stringify(pagination), isCall]);
  const handleKeyEnter = (e, isSearch) => {
    if (e.key === 'Enter') {
      setSearchParams({...searchParams, eventName: eventName})
    }
    else if(isSearch) {
        setSearchParams({...searchParams, eventName: eventName})
    }
  };
    
    const handleDetail = (item) => {
        navigate(`/event/detail?event=${item.eventId}`)
    }
    const handleCopy = (item) => {
        navigate(`/event/create?event=${item.eventId}`,
            { state: { isCopy: true, item: item }, replace: true }
        );
    }

    let dataNew = data.map(it => ({...it, centerName: it && it.appliedCenter && it.appliedCenter.map(ct => CenterDetail(ct, centerData) ) }))
    
    const handleCreate = async(centerId) => {
        let centerById = CenterDetails(centerId, centerData)
        navigate(
            `/event/create`,
            { state: { centerDetail: centerById, centerId: centerById && centerById?.locationID }, replace: true }
        );
    }

    return <div className="width-100 m-auto h-100 pt-2-">
        <div className="row pt-3- pb-3">
            <div className="col-8 d-flex align-items-center">
                <span
                    className="d-flex align-items-center me-5">
                    <span className="dashboardH3">Event</span>
                </span>
                {/* {CheckRoleType(user && user?.role) ? 
                <SelectMultiCustom
                    onSelected={(value) => {
                        setCenterId([...value]);
                        setPagination({...pagination,
                            current: 1
                        })
                    }}
                    currentSelect={centerId}
                    centerData={centerData}
                />
                : ""} */}
            </div>
            <div className="col-4 text-end">
                <Button 
                onClick={() => handleCreate(centerId)}
                 variant="primary" className="btn-cus" size="sm">
                    Create Event
                </Button>
            </div>
        </div>
        
        <div className="w-100 h-100- mt-2" 
        >
            <Card className="h-100 card-cus-" style={{minHeight: "33rem"}}>
                <Card.Body className="ps-0 pe-0">

                    <div style={{ marginBottom: "1rem" }}>
                        <NavItemNavigation
                            currentKey={currentPositionKey}
                            setCurrentKey={(value) => {
                                setCurrentPositionKey(value)
                                setEventName("")
                                setSearchParams({
                                    "eventName": "",
                                    "startDate": "",
                                    "endDate": "",
                                    "status": "active", //["active", "inactive", "deleted", "expired"]
                                    "centerId": "",
                                    "sortField": "_id",
                                    "sort": "desc",
                                })
                            }}
                            currentPage={currentPositionKey}
                            setCurrentPage={(value) => {
                                setCurrentPositionKey(value)
                                setEventName("")
                                setSearchParams({
                                    "eventName": "",
                                    "startDate": "",
                                    "endDate": "",
                                    "status": "active", //["active", "inactive", "deleted", "expired"]
                                    "centerId": "",
                                    "sortField": "_id",
                                    "sort": "desc",
                                })
                            }}
                            steps={positionSteps}
                            marginRight="1.69rem"
                            marginLeft="1rem"
                        />
                    </div>
                    
                    <div className="w-100 ps-3 pe-3">

                        <div className="w-100 h-100- mt-2" >
                            <div className="row">
                                <div className="col-sm-6 col-md-3 col-lg-3 mt-1">
                                    <Input
                                        size="large"
                                        style={{ maxWidth: "500px" }}
                                        placeholder="Search"
                                        value={eventName}
                                        height={"2.4rem"}
                                        styles={{
                                            height: "2.4rem"
                                        }}
                                        onChange={(e) => setEventName(e.target.value)}
                                        suffix={<CiSearch onClick={() => handleKeyEnter('', true)} role="button" />}
                                        onKeyDown={handleKeyEnter}
                                    />
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 mt-1">
                                    {CheckRoleType(user && user?.role) ?
                                        <SelectMultiCustom
                                            onSelected={(value) => {
                                                setCenterId([...value]);
                                                setPagination({
                                                    ...pagination,
                                                    current: 1
                                                })
                                            }}
                                            currentSelect={centerId}
                                            className="w-100"
                                            centerData={centerData}
                                            minWidth="100%"
                                            height={"2.6rem"}
                                            size={1}
                                        />
                                        : ""}
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6 mt-1'>
                                    <div className="row">
                                        <div className='col-5 col-sm-5 col-md-3 col-lg-3 mt-1'>
                                            <InputDate
                                                value={searchParams.startDate}
                                                onChange={(date) => {
                                                    setSearchParams({ ...searchParams, startDate: date ? timeUtils.formatData(date) : "" })
                                                }}
                                                id="startDate"
                                                placeholder='Start Date'
                                                max={searchParams.endDate}
                                            />
                                        </div>
                                        <div className='col-5 col-sm-5 col-md-3 col-lg-3 mt-1'>
                                            <InputDate
                                                value={searchParams.endDate}
                                                onChange={(date) => {
                                                    setSearchParams({ ...searchParams, endDate: date ? timeUtils.formatData(date) : "" })
                                                }}
                                                id="endDate"
                                                placeholder='End Date'
                                                min={searchParams.startDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-sm-12 col-md-3 col-lg-3 mt-1">
                                    <Input
                                        size="large"
                                        style={{ maxWidth: "500px" }}
                                        placeholder="Event name"
                                        value={eventName}
                                        height={"2.4rem"}
                                        styles={{
                                            height: "2.4rem"
                                        }}
                                        onChange={(e) => setEventName(e.target.value)}
                                        suffix={<CiSearch onClick={() => handleKeyEnter('', true) } role="button" />}
                                        onKeyDown={handleKeyEnter}
                                    />
                                </div>
                                <div className='col-sm-12 col-md-8 col-lg-8 mt-1'>
                                    <div className="row">
                                        <div className='col-5 col-sm-5 col-md-3 col-lg-3 mt-1'>
                                            <InputDate
                                                value={searchParams.startDate}
                                                onChange={(date) => {
                                                    setSearchParams({ ...searchParams, startDate: date ? timeUtils.formatData(date) : "" })
                                                }}
                                                id="startDate"
                                                placeholder='Start Date'
                                                max={searchParams.endDate}
                                            />
                                        </div>
                                        <div className='col-5 col-sm-5 col-md-3 col-lg-3 mt-1'>
                                            <InputDate
                                                value={searchParams.endDate}
                                                onChange={(date) => {
                                                    setSearchParams({ ...searchParams, endDate: date ? timeUtils.formatData(date) : "" })
                                                }}
                                                id="endDate"
                                                placeholder='End Date'
                                                min={searchParams.startDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div className="w-10">
                            {
                                dataNew && dataNew.map((it, i) => (
                                    <div className="mt-3"><CardComponent 
                                    data={it} 
                                    handleDetail={handleDetail}
                                    handleCopy={handleCopy}
                                    centerData={centerData}
                                    /></div>
                                ))
                            }
                        </div>
                        <div className="w-100 mt-3">
                        {total ? <Paginationcomponent total={total} pagination={pagination} setPagination={setPagination} /> : ""}
                        </div>
                    </div>
                    
                </Card.Body>
            </Card>
        </div>
        {loading ? <LoadingOverlay /> : null}
        <MessageModalMain textCentered ref={messageModalRef} />
    </div>
}
export default EventPage;