import * as React from 'react';

const CopySVG = ({ width, height, stroke, opacity }) => {
	return (
		<svg
			width={width ? width : "24"}
			height={height ? height : "24"}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M19 5H7V3H21V17H19V5Z" fill="currentColor" />
			<path d="M9 13V11H11V13H13V15H11V17H9V15H7V13H9Z" fill="currentColor" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 7H17V21H3V7ZM5 9H15V19H5V9Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default CopySVG;
