import { combineReducers } from "redux";

import authReducer from "./modules/auth/reducer";
import authReducers from "./modules/auths/reducer";
/**
 * Root reducer
 * @type {Reducer<any> | Reducer<any, AnyAction>}
 */
const rootReducers = combineReducers({
    auth: authReducer,
    authReducer: authReducer,
    auths: authReducers,

  });
  
export default rootReducers;