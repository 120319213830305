import React from 'react';
import './PageNotAccess.css';
import { useNavigate } from 'react-router-dom';

const PageNotAccess = () => {
  const navigate = useNavigate();
  return (
    <div className="notfound_body">
      <div className="notfound_content">
        <h1 style={{ fontSize: '11rem', fontWeight: '900', color: '#3f86c7' }}>403</h1>
        <div className="text_content container">
          <p id="pagenotfound">No Access</p>
          <button
            className="btnReturn"
            onClick={() => {
              navigate('/');
            }}
          >
            Return to Homepage
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageNotAccess;
