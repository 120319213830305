import { Input } from 'antd'
import React, { useRef, useState } from 'react'

export default function CustomInput({ onChange, value, isSubmit, placeholder, fixedText }) {
    // const [textAreaFocus, setTextAreaFocus] = useState(false);
    // const [textAreaHover, setTextAreaHover] = useState(false);
    const TextAreaRef = useRef();

    // const handleFocus = () => setTextAreaFocus(true);
    // const handleBlur = () => setTextAreaFocus(false);
    // const handleMouseOver = () => setTextAreaHover(true);
    // const handleMouseLeave = () => setTextAreaHover(false);
    // const handleFixedTextFocus = () => TextAreaRef.current.focus();

    return (
        <Input.Group>
            <div className={(isSubmit && !value) ? "" :'fixed-input-group'}>
                <div className="text16" style={{ padding: "4px 11px" }}>{fixedText}</div>
                {/* <Input 
                    value={fixedText} 
                    status={isSubmit && !value ? "error" : ""}
                    onFocus={handleFixedTextFocus} 
                    className={`fixed-text-custom ${isSubmit && !value ? "" : (textAreaFocus || textAreaHover)? "input-focus-color" : ""}`} 
                /> */}
                <Input.TextArea
                    // className="enable-text-custom"
                    ref={TextAreaRef}
                    // onFocus={handleFocus}
                    // onBlur={handleBlur}
                    // onMouseOver={handleMouseOver}
                    // onMouseLeave={handleMouseLeave}
                    status={isSubmit && !value ? "error" : ""}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                />
            </div>
        </Input.Group>
    )
}
