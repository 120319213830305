import React from 'react'
import { Modal } from 'react-bootstrap';
import './LoadingOverlay.css'

const LoadingOverlay = (props) => {
    const { title1, title2 } = props
    return (
        <Modal show={true} centered size="lg" backdropClassName="loading-modal-backdrop" className="loading-modal">
            <center>
                <div className="spinner" style={{ marginBottom: '37px' }}></div>
                <p style={{ fontWeight: '400', fontSize: '18px', width: '100%' }}>{title1}</p>
                <p style={{ fontWeight: '700', fontSize: '18px', width: '100%' }}>{title2}</p>
            </center>
        </Modal>
    )
}

export default LoadingOverlay