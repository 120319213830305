import React, { useState, useRef } from "react";
import { MAIN_USER_URL } from "../../config/urls";
import API from '../../utils/request'
import './changePassword.css'
import { validatePassword } from "../../utils/validation";
import { Buffer } from 'buffer'
import MessageModalMain from "../../components/container/modal/modalMessage/messageModalMain";

const ChangePassword = () => {

    const [password, setPassword] = useState("")
    const [currentPassword, setCurrentPassword] = useState("")
    const [repassword, setRepassword] = useState("")
    const [newPasswordInvalid, setNewPasswordInvalid] = useState("")
    const [rePasswordInvalid, setRePasswordInvalid] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [isSubmit, setIsSubmit] = useState(false);
    const messageModalRef = useRef('');
    const [loading, setLoading] = useState(false)

    const logoutHandler = () => {
        localStorage.clear();
        window.location.href = "/login";
    };

    const handleChangePassword = () => {
        setIsSubmit(true);
        if (!currentPassword || !password || !repassword) {
            return false;
        } else if (password !== repassword) {
            return false;
        } else if (!validatePassword(password)) {
            return false;
        }

        var params = {
            "oldPassword": Buffer.from(currentPassword).toString('base64'),
            "newPassword": Buffer.from(password).toString('base64'),
        }
        setLoading(true)
        API.post(MAIN_USER_URL + '/admin/change/password', params).then((res) => {
            setLoading(false);
            if (res.status === 1) {
                setPassword("")
                setCurrentPassword("")
                setRepassword("")
                setNewPasswordInvalid("")
                setRePasswordInvalid("")
                messageModalRef.current.showSuccess(res.message)
                setIsSubmit(false);
                setTimeout(() => {
                    logoutHandler();
                }, 5000);

            } else if (res.status === 404) {
                setPasswordError(res.message);
                if (res.message === "Invalid Old password") {
                    setPasswordError("Invalid old password")
                }
            }
        }).catch((err) => {
            messageModalRef.current.showWarning(err.message)
            setLoading(false)
        })

    }
    return (
        <div className="container" style={{ marginTop: '1rem' }}>
            <h1 className="passwordH3">Change Password</h1>
            <div className='col-12 mt-5 carborderd1 auth_body '>
                <div className="">
                    <MessageModalMain textCentered ref={messageModalRef} />
                    <div>
                        <div className="row ">
                            <label className="labelTextLogin col-12 col-md-3 col-sm-12">Current Password <span className="text-danger">*</span></label>
                            <div style={{ position: 'relative' }} className="col-12 col-md-9 col-sm-12">
                                <input
                                    style={{
                                        height: "50px",
                                    }}
                                    type="password"
                                    className={`form-control inputPersonal ${isSubmit && (!currentPassword || passwordError) ? "is-invalid" : ""}`}
                                    id="Email"
                                    value={currentPassword}
                                    onChange={(e) => {
                                        setCurrentPassword(e.target.value)
                                    }}
                                />
                                <div className="invalid-feedback">
                                    {isSubmit && !currentPassword ? "The current password is required" : isSubmit && passwordError ? passwordError : ""}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <label className="labelTextLogin col-12 col-md-3 col-sm-12">New Password <span className="text-danger">*</span></label>
                            <div style={{ position: 'relative' }} className="col-12 col-md-9 col-sm-12">
                                <input
                                    type="password"
                                    className={`form-control newPassword ${isSubmit && (!password || (password && !validatePassword(password))) ? "is-invalid" : ""}`}
                                    style={{
                                        height: "50px",
                                    }}
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                                <div className="invalid-feedback">
                                    {isSubmit && !password ? "The new password is required."
                                        : isSubmit && password && !validatePassword(password)
                                            ? "New password should be 8 to 32 characters long, and include numbers, uppercase, and lowercase letters."
                                            : ""
                                    }
                                </div>
                                {password && !validatePassword(password)
                                    ? null
                                    : <small className="" style={{ fontSize: "14px", color: '#949494', fontWeight: 400 }}>New password should be 8 to 32 characters long, and include numbers, uppercase, and lowercase letters</small>

                                }

                            </div>
                        </div>
                        <div className="row mt-5">
                            <label className="labelTextLogin col-12 col-md-3 col-sm-12">Confirm New Password <span className="text-danger">*</span></label>
                            <div style={{ position: 'relative' }} className="col-12 col-md-9 col-sm-12">
                                <input
                                    type="password"
                                    className={`form-control newPassword ${isSubmit && !repassword || (password !== repassword) ? "is-invalid" : ""}`}
                                    style={{
                                        height: "50px",
                                    }}
                                    value={repassword}
                                    onChange={(e) => {
                                        setRepassword(e.target.value);
                                    }}
                                />
                                <div className="invalid-feedback">
                                    {
                                        isSubmit && !repassword ? "The confirm password is required." : isSubmit && (password !== repassword) ? "Password does not match." : "The confirm password is required."
                                    }
                                </div>
                            </div>
                        </div>
                        <button
                            className="button_auth button_bodymesssage"
                            style={{ marginTop: "32px" }}
                            onClick={() => handleChangePassword()}
                        >
                            <p
                                style={{
                                    fontWeight: "800",
                                    fontSize: "16px",
                                    marginBottom: "0",
                                    color: "white",
                                }}
                            >
                                Submit
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;