import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ArrowRight from '../../assets/svgicons/ArrowRight';
import { formatDateSecondary } from '../../utils/dateFormatUtils';
import BackRightBtn from '../../assets/svgicons/BackRightBtn';
import ScanSVG from '../../assets/svgicons/ScanSVG';
import NoteSVG from '../../assets/svgicons/NoteSVG';
import { formatNumber } from '../../utils/string';
import CopySVG from '../../assets/svgicons/copySVG';
import { FaEye } from 'react-icons/fa';
import notfound from "../../assets/images/notfound.png"
import CarouselComponent from '../container/carousel/CarouselComponent';
import QrCodeGenerator from '../qrcode/QRCodeComponent';
import IconFormSubmit from '../../assets/svgicons/FormSubmit';
import IconScan from '../../assets/svgicons/Scan';
import { CenterDetail } from '../../utils/checkCenterLocation';
import { TbScanEye } from 'react-icons/tb';
import { Skeleton } from 'antd';
import { useState } from 'react';
import LegalAgeIcon from '../../assets/svgicons/LegalAgeIcon';
import AgreementIcon from '../../assets/svgicons/AgreementIcon';
import Paragraph from 'antd/es/typography/Paragraph';

function EventCardComponent({ data, handleDetail, handleCopy, slideIndex, setslideIndex, handlePreview, centerData, loading }) {
    let description = data && data.description ? data.description.split('\n') : []
    let eventDescriptionES = data && data.eventDescriptionES ? data.eventDescriptionES.split('\n') : []
    const [slideIndexFly, setslideIndexFly] = useState(0)
    const [expanded, setExpanded] = useState(false);

    return (
        <div className='row'>
            <div className='col-sm-12 col-md-3 col-lg-3'>
                {loading ? <Skeleton.Button className='custom-skeleton-button' loading={loading} active={true} size={'large'} shape={'default'} block={true} /> : 

                <div className='row h-100 d-flex ps-sm-1 ps-md-4 ps-lg-4' style={{alignContent: "space-around"}}>
                    <div className='col-sm-6 col-md-12'>
                        <IconScan width={"3.3rem"} height={"3.3rem"} />
                        <div className='font-0-875 mt-3'>Scanned</div> 
                        <div style={{ fontWeight: 600, fontSize: "24px" }}>{formatNumber(data && data.scanCount)}</div>
                    </div>
                    <div className='col-sm-6 col-md-12'>
                        <IconFormSubmit width={"3.3rem"} height={"3.3rem"}   />
                        <div className='font-0-875 mt-3'>Forms Submission</div> 
                        <div style={{ fontWeight: 600, fontSize: "24px" }}>{formatNumber(data && data.submissionCount)}</div>
                    </div>
                    <div className='col-sm-6 col-md-12'>
                        <LegalAgeIcon width={"3.3rem"} height={"3.3rem"}/>
                        <div className='font-0-875 mt-3'>Over 18 years old</div> 
                        <div style={{ fontWeight: 600, fontSize: "24px" }}>{formatNumber(data && data.legal_age_count)}</div>
                    </div>
                    <div className='col-sm-6 col-md-12'>
                        <AgreementIcon width={"3.3rem"} height={"3.3rem"}/>
                        <div className='font-0-875 mt-3'>Agree to the conditions</div> 
                        <div style={{ fontWeight: 600, fontSize: "24px" }}>{formatNumber(data && data.agreement_count)}</div>
                    </div>
                </div>}
            </div>
            <div className='col-sm-12 col-md-9 col-lg-9'>
               { loading ? <Skeleton.Button className='custom-skeleton-button' active={true} size={'large'} shape={'default'} block={true} /> :
                <Card>
                    <Card.Body>
                        <div className='row mt-1- align-items-center'>
                            <div className='col-3'>
                                <Card.Text className='text-label'>
                                    Event name
                                </Card.Text>
                            </div>
                            <div className='col-6'>
                                <Card.Text className='text-label2'>
                                    <span>{data && data.eventName}</span>
                                </Card.Text>
                            </div>
                            <div className='col-3 text-end'>
                                <Button
                                    onClick={() => handlePreview && handlePreview(data)}
                                    variant="outline-secondary" size="sm" 
                                    style={{ background: "none", 
                                    borderColor: "#a497975e",
                                    color: "#6c757d"}}>
                                    {/* <FaEye /> */}
                                    <TbScanEye />
                                    <span style={{verticalAlign: "middle"}} className='ps-1'>Preview</span>
                                </Button>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-3'>
                                <Card.Text className='text-label'>
                                    Event name (Spanish)
                                </Card.Text>
                            </div>
                            <div className='col-7'>
                                <Card.Text className='text-label2'>
                                    <span>{data && data.eventNameES ? data.eventNameES : ""}</span>
                                </Card.Text>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-3'>
                                <Card.Text className='text-label'>
                                    Event number
                                </Card.Text>
                            </div>
                            <div className='col-7'>
                                <Card.Text className='text-label2'>
                                    <span>{data && data.eventNumber}</span>
                                </Card.Text>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-3'>
                                <Card.Text className='text-label'>
                                    Event prize name
                                </Card.Text>
                            </div>
                            <div className='col-7'>
                                {
                                    description && description.map((dc, i) =>
                                        <Card.Text className='text-start m-0 text-label2'>
                                            {dc}
                                        </Card.Text>)
                                }
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-3 col-3 pe-0'>
                                <Card.Text className='text-label'>
                                    Event prize name (Spanish)
                                </Card.Text>
                            </div>
                            <div className='col-7'>
                                {
                                    eventDescriptionES && eventDescriptionES.map((dc, i) =>
                                        <Card.Text className='text-start m-0 text-label2'>
                                            {dc}
                                        </Card.Text>)
                                }
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-3'>
                                <Card.Text className='text-label'>
                                    Event center
                                </Card.Text>
                            </div>
                            <div className='col-6'>
                                <Card.Text className='text-label2'>
                                    <Paragraph
                                        ellipsis={{
                                            rows: 6,
                                            expandable: 'collapsible',
                                            expanded,
                                            onExpand: (_, info) => setExpanded(info.expanded),
                                            symbol: expanded ? "Load Less" : "Load More"
                                        }}
                                    >
                                        {data && data.eventCenter && data.eventCenter.map(ct => <p className='text-label2 m-0'>
                                            {CenterDetail(ct, centerData)}
                                        </p>)}
                                    </Paragraph>
                                </Card.Text>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-3'>
                                <Card.Text className='text-label'>
                                    Event date
                                </Card.Text>
                            </div>
                            <div className='col-6'>
                                <Card.Text className='text-label2'>
                                    <span>{data && data && data.startDate ? formatDateSecondary(data.startDate) : ''}</span> <span className='ps-2 pe-2'><BackRightBtn opacity={"0.4"} width={"1rem"} height={"1rem"} /></span> <span>{data && data && data.endDate ? formatDateSecondary(data.endDate) : ""}</span>
                                </Card.Text>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-3'>
                                <Card.Text className='text-label'>
                                    Event prize picture
                                </Card.Text>
                            </div>
                            <div className='col-3'>
                                <CarouselComponent 
                                data={data && data.images ? data.images : []}
                                slideIndex={slideIndex}
                                handleCurrentSlide={(value) => {
                                    setslideIndex && setslideIndex(value)
                                    setslideIndexFly(value)
                                }}
                                height={"8.438rem"}
                                width={"8.438rem"}
                                />
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-3'>
                                <Card.Text className='text-label'>
                                    Forms link
                                </Card.Text>
                            </div>
                            <div className='col-6'>
                                <QrCodeGenerator
                                urlQr={data && data.formLink}
                                data={data}
                                isDownload={true}
                                slideIndex={slideIndexFly}
                                setslideIndex={setslideIndexFly}
                                />
                            </div>
                        </div>
                    </Card.Body>
                </Card>}
            </div>
        </div>
    );
}

export default EventCardComponent;