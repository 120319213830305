export const sliceURL = (sliceURL) => {
    let fileNameUpload = sliceURL;
    let parser = document.createElement('a');
    parser.href = fileNameUpload;
    let fileName = parser.pathname.split('/').slice(1);
    let lastElement = fileName.pop()

    if (lastElement.length > 30) {
        return lastElement.substr(0, 30) + '...' + lastElement.substr(-11)
    }

    return lastElement
};