import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Spin } from "antd";
import CloseBtn from "../../../../assets/svgicons/CloseBtn";
import "./style.css"
import PreviewCardComponent from "../../../card/PreviewCardComponent";
import PreviewCreateCardComponent from "../../../card/PreviewCreateCardComponent";
import PreviewDetailCardComponent from "../../../card/PreviewDetailCardComponent";
import PreviewCreateCardComponentNew from "../../../card/PreviewCreateCardComponentNew";

const EventPreviewModal = forwardRef(({
}, ref) => {
  let location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [codeLang, serCodeLang] = useState("en")

  const open = async(item) => {
    setIsOpen(true)
    serCodeLang("en")
    item && setData(item)
  }
  const close = async () => {
    setData(null)
    setIsOpen(false)
  }

  useImperativeHandle(ref, () => {
    return { open: open, close: close }
  })


  return <>
    <Modal
      show={isOpen}
      onHide={() => setIsOpen(false)}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-preview"
      style={{
        borderRadius: "0.625rem",
        width: "110%",
        transform: "translateX(-4.5%)",
      }}
    >
      <Modal.Body style={{
        // minWidth: "699px", 
        overflow: "auto"}}>
      <Spin size="large" tip="Loading..." spinning={loading}>
        <div style={{ textAlign: "right", position: "absolute", right: "0px", marginTop: "-13px" }}>
          <span onClick={() => setIsOpen(false)}  style={{cursor: "pointer"}}><CloseBtn /></span>
        </div>
        <div
          className="w-100 h-100 d-flex flex-column align-items-center"
          // style={{ paddingLeft: "3.3rem", paddingRight: "3.3rem" }}
        >
          <div className="d-flex flex-row align-items-center justify-content-center w-100 pt-2 pb-3" style={{ position: 'relative' }}>
            <div className="notes_title d-flex">
              <div style={{fontSize: "1.3rem"}}>Event Preview</div>
              <div className="btn-lang" style={{cursor: "pointer"}}>
                <span onClick={() => serCodeLang("en")} className={`btn-lang-change ${codeLang === 'en' ? "btn-lang-change-active" : ""} me-2`}>Enlish</span>
                <span onClick={() => serCodeLang("es")}  className={`btn-lang-change ${codeLang === 'es' ? "btn-lang-change-active" : ""}`}>Spanish</span>
              </div>
            </div>

          </div>
          
          <div className="w-100 ">

            <div className="w-100">
              {/* <PreviewCardComponent 
              data={data}
              /> */}
              {/* <PreviewDetailCardComponent 
              data={data}
              /> */}
              <PreviewCreateCardComponentNew codeLang={codeLang} serCodeLang={serCodeLang} data={data}/>
            </div>

          </div>
        </div>
        </Spin>
      </Modal.Body>
    </Modal>
  </>
});

const styles = {
  text_title_list: {
    color: "#152C5B",
    fontWeight: 600,
    fontSize: "0.875rem",
    opacity:"80%",
    margin:"0.6rem"
  },
};

export default EventPreviewModal;