import { Button } from "react-bootstrap";
import React from "react";
import BackLeftBtn from "../../../assets/svgicons/BackLeftBtn";

const BackComponent = ({
    onClick, onClickCreate, onClickEdit, title, titleCreate, titleEdit, isEdit, isCreate, status, onClickChangeStatus, isRefresh
}) => {
    return <div className="row pt-3- pb-3">
        <div className="col-8 d-flex align-items-center">
            <span
                onClick={onClick && onClick}
                style={{cursor: "pointer"}}
                className="d-flex align-items-center">
                <span
                    style={{ border: "1px solid rgba(0, 0, 0, 0.175)", borderRadius: "6px", height: "1.5rem" }}
                    className='d-flex align-items-center justify-content-center ps-2 pe-2 me-2'><BackLeftBtn opacity={"0.4"} width={"0.9rem"} height={"0.9rem"} /></span>
                <span className="dashboardH3">{title}</span>
            </span>
            {isRefresh ? <span className="dashboardH3">{isRefresh}</span> : ''}
        </div>
        {isCreate ? <div className="col-4 text-end">
            <Button
                onClick={() => onClickCreate && onClickCreate}
                variant="primary" className="btn-cus" size="sm">
                {titleCreate}
            </Button>
        </div> : ''}

        {!isCreate && isEdit !== undefined ? <div className="col-4 text-end d-flex gap-3 justify-content-end">
            <Button
            disabled={isEdit}
             onClick={onClickChangeStatus && onClickChangeStatus}
             variant="primary" size="sm" style={{ background: "none", borderColor: (status ? "rgb(33, 194, 115)" : "rgb(149, 163, 187)") ,color: (status ? "rgb(33, 194, 115)" : "rgb(149, 163, 187)") }}>
             <span className='ps-1'>Make {status ? "Inactive" : "Active"}</span>
            </Button>
            <Button
                onClick={onClickEdit && onClickEdit}
                variant="primary" size="sm" style={{ background: "none", color: "#0d6efd" }}>
               
                <span className='ps-1'>{titleEdit}</span>
            </Button>
        </div> : ''}
    </div>
}
export default BackComponent;