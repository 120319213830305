import React from "react";

export default function Expired() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ flex: 1 }}
    >
      <div>
        <div className="text_content container">
          <p id="pagenotfound">EXPIRED</p>
          <p id="sorrynotfound">Event has been expired</p>
        </div>
      </div>
    </div>
  );
}
