export const storageKeys = {
    TOKEN: "healthprogramtoken",
    USER: "USER",
    NAME: "NAME",
    OPEN_MODAL_AFTER_LOGGIN_SUCCESS: "OPEN_MODAL_AFTER_LOGGIN_SUCCESS",
    ALREADY_SHOW_LOYAL_PROGRAM: "ALREADY_SHOW_LOYAL_PROGRAM",
    USERNEWREGISTER: 'NEWUSER',
    REGISTERURL: 'URLREGISTER',
    REGISTERPATHNAME: 'PATHNAME',
    VERIFY: "VERIFY"
}