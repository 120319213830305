import * as React from "react";
import ReactToPrint from "react-to-print";
import { ComponentToPrint } from "./ComponentToPrint";

export class ClassComponent extends React.PureComponent {
  componentRef = null;
  printBtnRef = null;

  constructor(props) {
    super(props);
  }

  handleAfterPrint = () => {
    console.log("`onAfterPrint` called");
  };

  handleBeforePrint = () => {
    console.log("`onBeforePrint` called");
  };

  setComponentRef = (ref) => {
    this.componentRef = ref;
  };

  reactToPrintContent = () => {
    return this.componentRef;
  };

  handlePrint = () => {
    this.printBtnRef.click()
  }

  reactToPrintTrigger = () => {
    return <span ref={(ref) => this.printBtnRef = ref} /> ;
  };

  render() {
    const { reportResult } = this.props;
    return (
      <div>
        <ReactToPrint
          content={this.reactToPrintContent}
          documentTitle="AwesomeFileName"
          onAfterPrint={this.handleAfterPrint}
          onBeforePrint={this.handleBeforePrint}
          removeAfterPrint
          trigger={this.reactToPrintTrigger}
        />
        <div style={{display: 'none'}}>
            <ComponentToPrint reportResult={reportResult} ref={this.setComponentRef} />
        </div>
      </div>
    );
  }
}
