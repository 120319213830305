import React, { useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import USA from "../../assets/images/USA.png";
import Mexico from "../../assets/images/Mexico.png";
import Canada from "../../assets/images/Canada.png";
import { Modal } from "antd";
import { set } from "lodash";
export default function CountryModal({
  currentCountry,
  setCurrentCountry,
  size,
}) {
  const [open, setOpen] = useState(false);
  const ListCountry = [
    {
      path: USA,
      code: 1,
      name: "United States",
    },
    {
      path: Canada,
      code: 1,
      name: "Canada",
    },
    {
      path: Mexico,
      code: 52,
      name: "Mexico",
    },
  ];

  const handleFlagClick = () => {
    setOpen(true);
  };

  const handleChangeCountry = (country) => {
    setOpen(false);
    setCurrentCountry({
      code: country.code,
      name: country.name,
    });
  };

  const getCurrentCountry = () => {
    return ListCountry.filter((i) => i.name === currentCountry.name)[0];
  };

  return (
    <>
      <div
        className="d-flex align-items-center"
        onClick={handleFlagClick}
        role="button"
      >
        <img
          src={getCurrentCountry().path}
          className={
            size === "large" ? "country-modal-flag" : "event-country-modal-flag"
          }
          alt="Country Flag"
        />
        <span
          className={
            size === "large" ? "country-modal-code" : "event-country-modal-code"
          }
        >
          {" "}
          +{getCurrentCountry().code}
        </span>
      </div>
      <Modal
        centered
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        closable
        closeIcon={<IoIosCloseCircleOutline fontSize={22} />}
      >
        <div>
          <h6>Select Country</h6>
          {ListCountry.map((item, index) => (
            <div key={item.name + ", " + index}>
              <div
                className="py-2 d-flex align-items-center justify-content-between"
                role="button"
                onClick={() => handleChangeCountry(item)}
              >
                <div className="d-flex align-items-center gap-3">
                  <img
                    src={item.path}
                    style={{ width: "36px", height: "22px", marginLeft: "6px" }}
                    alt="Country Flag"
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#6c757d",
                    }}
                  >
                    {item.name}
                  </span>
                </div>
                <span
                  style={{
                    fontSize: "12px",
                    color: "#6c757d",
                  }}
                >
                  {item.code}
                </span>
              </div>
              <hr className="m-0" />
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
}
