export const SET_TOKEN = 'SET_TOKEN';
export const DELETE_TOKEN = 'DELETE_TOKEN';

export const SET_USER = 'SET_USER';
export const DELETE_USER = 'DELETE_USER';

export const DONOR_SAVE_LOCATION = 'DONOR_SAVE_LOCATION';
export const DONOR_DELETE_LOCATION = 'DONOR_DELETE_LOCATION';

export const UPDATE_PROFILE_PICTURE = 'UPDATE_PROFILE_PICTURE';

export const UPDATE_FIRST_NAME = 'UPDATE_FIRST_NAME';
export const UPDATE_MIDDLE_NAME = 'UPDATE_MIDDLE_NAME';
export const UPDATE_LAST_NAME = 'UPDATE_LAST_NAME';

export const UPDATE_BIRTH_DATE = 'UPDATE_BIRTH_DATE';
export const UPDATE_GENDER = 'UPDATE_GENDER';

export const UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_MAILING_ADDRESS = 'UPDATE_MAILING_ADDRESS';

export const UPDATE_COMMUNICATION = 'UPDATE_COMMUNICATION';
export const DELETE_PROMPT = 'DELETE_PROMPT';
