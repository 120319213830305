import React, { useEffect, useRef, useState } from 'react'
import './styles/login.page.css'
import { Button, Form, Input, Spin, message } from 'antd'
import { storageKeys } from '../constants/storage';
import { Buffer } from 'buffer'
import API from '../utils/request'
import { LOGIN_URL } from '../config/urls';
import * as Storage from "../utils/storage";
import MessageModalMain from '../components/container/modal/modalMessage/messageModalMain';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LOGIN_SUCCESS } from '../modules/auth/constants';
import LoadingOverlay from '../components/container/loading/LoadingOverlay';

const LoginPage = () => {
    const [loginForm] = Form.useForm();
    const [submitting, setIsSubmitting] = useState(false);
    const [formError, setFormError] = useState(false);
    const [loading, setLoading] = useState(false)
    const messageModalRef = useRef('rememberMe');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onFieldsChange = async () => {
        const hasErrors = loginForm.getFieldsError().some(({ errors }) => errors.length);
        setFormError(hasErrors);
    };

    
    useEffect(() => {
        if (localStorage.getItem(storageKeys.TOKEN)) {
            window.location.href = "/";
        }
    }, [])

    const handleSubmit = (e) => {
        try {
            let data = loginForm.getFieldsValue()
            setLoading(true)
            API.post(LOGIN_URL, {
                "email": data.email,
                "password": Buffer.from(data.password).toString('base64')
            })
                .then((res) => {
                    if (res.status === 1) {
                        Storage.setString(storageKeys.TOKEN, res.token);
                        Storage.setJson(storageKeys.USER, res.data);
                        Storage.setJson(storageKeys.NAME, res.data.fullName);
                        dispatch({
                            type: LOGIN_SUCCESS,
                            payload: {
                              token: res.token,
                              user: res.data
                            }
                          });
                          navigate(`/dashboard`)
                    } else {
                        messageModalRef.current.showWarning(res?.message);
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    messageModalRef.current.showWarning(typeof err.message === 'string' ? err.message : "Internal Server Error!");
                    setLoading(false)
                })
        } catch (error) {
            message.error('Internal Server Error!')
            setLoading(false)
        }
    }
    if (localStorage.getItem(storageKeys.TOKEN)) {
        return null;
    }

    const onFinish = (loginCredential) => {
        try {
            setIsSubmitting(true)
            handleSubmit(loginCredential)
        } catch (err) {
            setIsSubmitting(false)
        } finally {
            setIsSubmitting(false)
        }
    };
    return (
        <div className='container-lg auth-layout'>
             <MessageModalMain textCentered ref={messageModalRef} />
            <h3 className='auth-title'>Event Management</h3>
            <div className='auth-card'>
                <h3 className='auth-title'>Login</h3>
                <Form
                    form={loginForm}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFieldsChange={onFieldsChange}
                    layout="vertical"
                    size="large"
                    autoComplete={'off'}
                    className='w-100'
                    requiredMark='optional'
                >
                    <Form.Item
                        name="email"
                        label={<label className="auth-label">Email <span className="text-danger">*</span></label>}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                            },
                        ]}
                        className='m-0'
                    >
                        <Input style={{ height: '50px' }} />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label={<label className="auth-label">Password <span className="text-danger">*</span></label>}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        className='password-layout'
                        style={{ marginBottom: '32px' }}
                    >
                        <Input.Password style={{ height: '50px' }} className='d-flex align-items-center ps-0' />
                    </Form.Item>

                    <Form.Item>
                        <Button className='login-button' type="primary" htmlType="submit" loading={submitting} disabled={formError}>
                            Log In
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            {loading ? <LoadingOverlay /> : null}
        </div>
    )
}

export default LoginPage