import i18next from "i18next";
import * as Actions from "./constants";

const initState = {
    user: null,
    token: "",
    isFromMobile: false,
    lang: i18next.language.indexOf('es') > -1 ? "es" : "en",
    registerInfo: {
      token: "",
      donorNumber : "",
      identValueLast4: "",
      idenTypeID: "",
      firstName: "",
      lastName: "",
      dob: "",
      email: "",
      phone: null,
      isSentCaptcha: false,
      code: "",
    },
    predonorRegisterInfo: {
      token: "",
      firstName: "",
      lastName: "",
      dob: "",
      gender: "",
      savedLocations: [],
      email: "",
      phone: null,
      isSentCaptcha: false,
      code: "",
    }

}

export default function authReducer(state = initState, action = {}) {
  switch (action.type) {
    case Actions.LOGIN_SUCCESS:
      return {
        user: action.payload.user,
        token: action.payload.token
      };
    case Actions.LOGIN_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case Actions.RESTORE_USER:
      return {
        ...state,
        user: action.payload
      };
    case Actions.UPDATE_DONOR_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          primaryEmail: action.payload
        }
      };
    case Actions.UPDATE_DONOR_PHONE:
      return {
        ...state,
        user: {
          ...state.user,
          primaryPhoneNumber: action.payload
        }
      };
    case Actions.UPDATE_DONOR_COMPREF:
      return {
        ...state,
        user: {
          ...state.user,
          communicationPreferences: action.payload
        }
      };
    case Actions.UPDATE_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          donorThumbnailPath: action.payload
        }
      };
    case Actions.UPDATE_LOCATION:
      return {
        ...state,
        user: {
          ...state.user,
          locations: action.payload
        }
      };
    case Actions.SET_IS_FROM_MOBILE:
      return {
        ...state,
        isFromMobile: true
      };
    case Actions.SET_REGISTER_INFO:
      return {
        ...state,
        registerInfo: action.payload
      };
    case Actions.SET_PREDONOR_REGISTER_INFO:
      return {
        ...state,
        predonorRegisterInfo: action.payload
      };
    case Actions.SET_REGISTER_CONTACT_INFO:
      return {
        ...state,
        registerInfo: {
          ...state.registerInfo,
          email: action.payload.email,
          phone: action.payload.phone,
          isSentCaptcha: true
        }
      };
    case Actions.SET_REGISTER_VERIFY_CODE:
      return {
        ...state,
        registerInfo: {
          ...state.registerInfo,
          code: action.payload
        }
      };
    case Actions.SET_PREDONOR_REGISTER_VERIFY_CODE:
      return {
        ...state,
        predonorRegisterInfo: {
          ...state.predonorRegisterInfo,
          code: action.payload.code,
          token: action.payload.tempToken
        }
      };
    case Actions.CHANGE_LANGAUGE:
      return {
        ...state,
        lang: action.payload
      };
    default:
      return state;
  }
}
  