import {
    DELETE_PROMPT,
    DONOR_SAVE_LOCATION,
    DONOR_DELETE_LOCATION,
    DELETE_TOKEN,
    SET_TOKEN,
    SET_USER,
    DELETE_USER,
    UPDATE_PROFILE_PICTURE,
    UPDATE_FIRST_NAME,
    UPDATE_MIDDLE_NAME,
    UPDATE_LAST_NAME,
    UPDATE_BIRTH_DATE,
    UPDATE_GENDER,
    UPDATE_PHONE_NUMBER,
    UPDATE_EMAIL,
    UPDATE_MAILING_ADDRESS,
    UPDATE_COMMUNICATION,
} from '../auths/constants';

const authReducer = (
    state = {
        token: null,
        user: null,
    },
    action,
) => {
    switch (action.type) {
        case SET_TOKEN:
            return { ...state, token: action.payload };
        case DELETE_TOKEN:
            return { ...state, token: null };
        case SET_USER:
            return { ...state, user: action.payload };
        case DELETE_USER:
            return { ...state, user: null, token: null };

        case DONOR_SAVE_LOCATION:
            var userReplica = Object.assign({}, state.user);
            userReplica.savedLocations.push(action.payload);
            if (
                userReplica.status === 'referral-pending' ||
                userReplica.status === 'pre-donor'
            ) {
                userReplica.lastDonationLocationId = action.payload;
            }
            return { ...state, user: userReplica };

        case DONOR_DELETE_LOCATION:
            var userReplica1 = Object.assign({}, state.user);
            for (var i = 0; i < userReplica1.savedLocations.length; i++) {
                if (userReplica1.savedLocations[i] === action.payload) {
                    userReplica1.savedLocations.splice(i, 1);
                }
            }
            if (
                userReplica1.savedLocations.length < 1 &&
                (userReplica1.status === 'referral-pending' ||
                    userReplica1.status === 'pre-donor')
            ) {
                userReplica1.lastDonationLocationId = null;
            }
            if (
                userReplica1.savedLocations.length > 0 &&
                (userReplica1.status === 'referral-pending' ||
                    userReplica1.status === 'pre-donor')
            ) {
                userReplica1.lastDonationLocationId =
                    userReplica1.savedLocations[userReplica1.savedLocations.length - 1];
            }
            return { ...state, user: userReplica1 };

        case UPDATE_PROFILE_PICTURE:
            var userReplica2 = Object.assign({}, state.user);
            userReplica2.donorThumbnailPath = action.payload;
            return { ...state, user: userReplica2 };

        case UPDATE_FIRST_NAME:
            var userReplica3 = Object.assign({}, state.user);
            userReplica3.firstName = action.payload;
            return { ...state, user: userReplica3 };
        case UPDATE_MIDDLE_NAME:
            var userReplica4 = Object.assign({}, state.user);
            userReplica4.middleName = action.payload;
            return { ...state, user: userReplica4 };
        case UPDATE_LAST_NAME:
            var userReplica5 = Object.assign({}, state.user);
            userReplica5.lastName = action.payload;
            return { ...state, user: userReplica5 };
        case UPDATE_BIRTH_DATE:
            var userReplica6 = Object.assign({}, state.user);
            userReplica6.birthDate = action.payload;
            return { ...state, user: userReplica6 };
        case UPDATE_GENDER:
            var userReplica7 = Object.assign({}, state.user);
            userReplica7.gender = action.payload;
            return { ...state, user: userReplica7 };
        case UPDATE_PHONE_NUMBER:
            var userReplica8 = Object.assign({}, state.user);
            userReplica8.primaryPhoneNumber = action.payload;
            return { ...state, user: userReplica8 };
        case UPDATE_EMAIL:
            var userReplica9 = Object.assign({}, state.user);
            userReplica9.primaryEmail = action.payload;
            return { ...state, user: userReplica9 };
        case UPDATE_MAILING_ADDRESS:
            var userReplica10 = Object.assign({}, state.user);
            userReplica10.address = action.payload;
            return { ...state, user: userReplica10 };
        case UPDATE_COMMUNICATION:
            var userReplica11 = Object.assign({}, state.user);
            userReplica11.communicationPreferences = action.payload;
            return { ...state, user: userReplica11 };

        case DELETE_PROMPT:
            var userReplica12 = Object.assign({}, state.user);
            userReplica12.promptActivation = null;
            return { ...state, user: userReplica12 };

        default:
            return state;
    }
};
export default authReducer;
