import * as React from 'react';

const BackRightBtn = ({width, height, opacity}) => {
	return (
		<svg width={width ? width : "1.25rem"} height={height ? height : "1rem"} opacity={opacity ? opacity : "1"} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M19.4741 8C19.4741 7.3452 18.9055 6.81438 18.2041 6.81438H1.2702C0.568778 6.81438 0.000169754 7.3452 0.000169754 8C0.000169754 8.6548 0.568778 9.18562 1.2702 9.18562H18.2041C18.9055 9.18562 19.4741 8.6548 19.4741 8Z" fill="#152C5B"></path>
			<path fillRule="evenodd" clipRule="evenodd" d="M19.1019 8.83836C19.5979 8.37535 19.5979 7.62465 19.1019 7.16164L12.3284 0.838357C11.8324 0.375345 11.0283 0.375345 10.5323 0.838357C10.0363 1.30137 10.0363 2.05206 10.5323 2.51507L17.3058 8.83836C17.8018 9.30137 18.606 9.30137 19.1019 8.83836Z" fill="#152C5B"></path>
			<path fillRule="evenodd" clipRule="evenodd" d="M19.1021 7.16165C18.6062 6.69863 17.802 6.69863 17.306 7.16165L10.5325 13.4849C10.0365 13.9479 10.0365 14.6986 10.5325 15.1616C11.0285 15.6247 11.8326 15.6247 12.3286 15.1616L19.1021 8.83836C19.5981 8.37535 19.5981 7.62466 19.1021 7.16165Z" fill="#152C5B"></path>
		</svg>
	);
}

export default BackRightBtn;
