import { put, takeLatest, select } from 'redux-saga/effects';
import * as Actions from '../../../modules/auth/constants'

function* ExTime(action) {
    const { history } = action.payload;
    try {
        const state = yield select();
        const tokens = state.authReducer.token;
        if (tokens) {
            const jwtPayload = JSON.parse(atob(tokens.split('.')[1]))
            const JWTtime = jwtPayload.exp * 1000
            if (Date.now() > JWTtime) {
                let data = {
                    user: '',
                    token: ''
                }
                yield put({
                    type: Actions.LOGIN_SUCCESS,
                    payload: data,
                });

                yield put({
                    type: Actions.RESTORE_USER,
                    payload: '',
                });
                history.push('/login')
            }
        }
    } catch (error) {
        console.log("error", error)
    }
}

export function* fetchAfterExpiryTimeWatcher() {
    yield takeLatest('AFTER_EXPIRY_TIME', ExTime);
}

