
const FooterComponent = ({
    token, navItems
}) => {

    return <div className='w-100'>
        <div expand="lg" className="bg-body-tertiary- nav-header- pt-2 pb-2" style={{ zIndex: 50 }}>
            <div className='csl-confined-header- csl-header-'>
                <div className='d-flex flex-row align-items-center h-100 w-100'>
                    <div className='row text-center w-100 m-auto'>
                        <div className='col-12'>
                            <span style={{fontSize: "0.7rem"}} className='m-0'>Powered by NowBST Technology</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default FooterComponent;