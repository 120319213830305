import { Button, Checkbox, ConfigProvider, Input, Select } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import "./SelectMultiCustom.css";

export default function SelectMultiCustom ({ onSelected, currentSelect, centerData, width, size, className, disabled, maxTagCount, minWidth, height }) {
    const [selected, setSelected] = useState([]);
    const [openDropDown, setOpenDropDown] = useState(false);

    useEffect(() => {
        setSelected(currentSelect);
    }, [currentSelect])

    const handleDropdownVisibleChange = (visible) => {
      setOpenDropDown(visible);
      if (!visible) {
        if (
            _.difference(selected, currentSelect).length > 0 ||
            _.difference(currentSelect, selected).length > 0
        ) {
            onSelected([...selected]);
        }
      } else {
        setSelected([...currentSelect]);
      }
    };
  
    const handleSelected = (item, checked) => {
      if (checked === false) {
        setSelected([..._.difference(selected, [item])]);
      } else {
        setSelected([...selected, item]);
      }
    };

  const selectAll = () => {
    const allValues = centerData.map(item => JSON.stringify(item));
    setSelected(allValues);
  };
  
    return (
      <ConfigProvider
        theme={{
          components: {
            Select: {
              optionSelectedBg: "none",
            },
          },
        }}
      >
        <Select
          style={{
            width: (width ?? "fit-content"),
            minWidth: minWidth ? minWidth : 300,
            height:  height
          }}
          size={size ?? "default"}
          placeholder="Select plasma Center"
          mode="multiple"
          maxTagCount={maxTagCount ?? 1}
          className={`${className}`}
          showSearch={true}
          disabled={disabled ?? false}
          optionRender={(data) => {
            const label = data.label;
            const center = data.value;
            return (
              <div
                className="w-100 d-flex gap-2"
                onClick={(e) => handleSelected(center)}
              >
                <Checkbox
                  checked={_.difference([center], selected).length === 0}
                ></Checkbox>
                <div
                  style={{
                    color:
                      _.difference([center], selected).length === 0
                        ? "#1677ff"
                        : "black",
                  }}
                >
                  {label}
                </div>
              </div>
            );
          }}
          onDropdownVisibleChange={handleDropdownVisibleChange}
          onSelect={(data) => {
            handleSelected(data);
          }}
          onDeselect={(item) => {
            if (openDropDown) {
              setSelected([..._.difference(selected, [item])]);
            } else {
              onSelected([..._.difference(selected, [item])]);
            }
          }}
          dropdownRender={(menu) => {
            return (
              <div className="px-2 py-1">
                {menu}
                <Button type="link" onClick={() => setSelected([])}>
                  RESET
                </Button>
                <Button type="link" onClick={() => selectAll()}>
                  All
                </Button>
              </div>
            );
          }}
          menuItemSelectedIcon={false}
          value={selected}
          options={centerData.map((item) => ({
            label: "CSL Plasma, " + item.name,
            value: JSON.stringify(item),
          }))}
        />
      </ConfigProvider>
    );
  };