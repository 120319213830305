export const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
export const LOGIN_TOKEN = "auth/LOGIN_TOKEN";
export const RESTORE_USER = "auth/RESTORE_USER";
export const UPDATE_DONOR_EMAIL = "auth/UPDATE_DONOR_EMAIL";
export const UPDATE_DONOR_PHONE = "auth/UPDATE_DONOR_PHONE";
export const UPDATE_DONOR_COMPREF = "auth/UPDATE_DONOR_COMPREF";
export const UPDATE_AVATAR = "auth/UPDATE_AVATAR";
export const SET_IS_FROM_MOBILE = "auth/SET_IS_FROM_MOBILE";
export const UPDATE_LOCATION = "auth/UPDATE_LOCATION";

export const SET_REGISTER_INFO = "auth/SET_REGISTER_INFO";
export const SET_REGISTER_CONTACT_INFO = "auth/SET_REGISTER_CONTACT_INFO";
export const SET_REGISTER_VERIFY_CODE = "auth/SET_REGISTER_VERIFY_CODE";

export const SET_PREDONOR_REGISTER_INFO = "auth/SET_PREDONOR_REGISTER_INFO";
export const SET_PREDONOR_REGISTER_VERIFY_CODE = "auth/SET_PREDONOR_REGISTER_VERIFY_CODE";

export const CHANGE_LANGAUGE = "auth/CHANGE_LANGAUGE";
