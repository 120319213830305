import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n/config';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { rootSaga, store, sagaMiddleware } from './modules/redux/sagas';
import ConnectedApp from './App';
import LoginPage from './pages/login.page';
import PageNotFound from './pages/page-not-found.page';
import EventPage from './pages/event/EventPage';
import SubmissionFormPage from './pages/submissionForm.page';
import FormSubmissionLayout from './components/layouts/form-submission.layout';
import EventCreatePage from './pages/event/EventCreatePage';
import EventDetailPage from './pages/event/EventDetailPage';
import { storageKeys } from "./constants/storage";
import * as Storage from './utils/storage';
import ChangePassword from './pages/auth/changePassword';
import ChangePasswordId from './pages/auth/changePasswordId';
import GuardedRoutes from './components/guarded-route/guarded-routes';
import FormSuccess from './pages/submit-success/form-submit-success';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Dashboard = React.lazy(() => import('./pages/dashboard/dashboard'));
const User = React.lazy(() => import('./pages/user/user'));

const AppWrapper = () => {
  var tokens = Storage.getString(storageKeys.TOKEN);
  const tokenR = useSelector((state) => state.authReducer.token);
  let token = tokens ? tokens : tokenR
  
  return (
    <Router>
      <Routes>
        <Route element={<FormSubmissionLayout />}>
          <Route path='/ShareFormPage' element={<SubmissionFormPage />} />
          <Route path='/success' element={<FormSuccess />} />
        </Route>
        <Route element={!token ? <Navigate to="/login" /> : <ConnectedApp />}>
          <Route path='/' element={<Navigate to="/dashboard" />} />
          <Route element={<GuardedRoutes accessingRight={`/event`} />}>
            <Route path='/event' element={<EventPage />} />
            <Route path='/event/create' element={<EventCreatePage />} />
            <Route path='/event/detail' element={<EventDetailPage />} />
          </Route>
          <Route path='/change/password' element={<ChangePassword />} />
          <Route element={<GuardedRoutes accessingRight={`/dashboard`} />}>
            <Route path='/dashboard' element={<Suspense ><Dashboard /></Suspense>} />
          </Route>
          <Route element={<GuardedRoutes accessingRight={`/user-management`} />}>
            <Route path='/user-management' element={<Suspense><User /></Suspense> } />
          </Route>
        </Route>
        <Route element={<ConnectedApp />}>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/set-new-password' element={<ChangePasswordId />} />
        </Route>
        <Route element={<ConnectedApp />}>
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

root.render(
  <Provider store={store}>
    <AppWrapper />
  </Provider>
);
