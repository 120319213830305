import { useEffect, useRef, useState } from "react";
import "./otp-input.css";
import { Input } from "antd";

const OtpInput = ({
  value = "",
  onChange = (value) => {},
  length = 4,
  onOtpSubmit = () => {},
  type = "number",
  error = false,
}) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);

  useEffect(() => {
    setOtp(new Array(length).fill(""));
  }, [length])

  useEffect(() => {
    if (value === "") {
      setOtp(new Array(length).fill(""));
    }
  }, [value]);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value) && type === "number") return;

    const newOtp = [...otp];
    // allow only one input
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // submit trigger
    const combinedOtp = newOtp.join("");
    onChange(combinedOtp);
    if (combinedOtp.length === length) onOtpSubmit(combinedOtp);

    // Move to next input if current field is filled
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    // optional
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      // Move focus to the previous input field on backspace
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div
      className={`custom-otp-input ${error ? "custom-otp-input-error" : ""}`}
    >
      {otp.map((value, index) => {
        return (
          <Input
            key={index}
            type={type === 'number' ? "tel" : "text"}
            ref={(input) => (inputRefs.current[index] = input)}
            value={value}
            onChange={(e) => handleChange(index, e)}
            onClick={() => handleClick(index)}
            onKeyDown={(e) => handleKeyDown(index, e)}
          />
        );
      })}
    </div>
  );
};
export default OtpInput;
