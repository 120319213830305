import { message, Table } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import API from '../../utils/request'
import { EVENT_RANDOM_WINNER, EVENT_REMOVE_WINNER, EVENT_WINNER } from '../../config/urls'
import MessageModalMain from '../../components/container/modal/modalMessage/messageModalMain'
import { formatDateTimeSlasMDYA } from '../../utils/dateFormatUtils'
import { useSelector } from 'react-redux'
import { storageKeys } from "../../constants/storage";
import * as Storage from '../../utils/storage';

const ChooseWinnerComponent = forwardRef(({ eventId }, ref) => {
    const winnerRef = useRef();
    const defaultKey = {
        "_id": "",
        "eventId": "",
        "formId": "",
        "eventNumber": "",
        "isWinner": true,
        "winnerHasRemoved": false,
    };
    const [winner, setWinner] = useState(defaultKey);
    const [listLoading, setListLoading] = useState(true);
    const [noWinner, setNoWinner] = useState(false);
    const messageModalRef = useRef('rememberMe');
    const [columns, setColumns] = useState([]);
    const user = useSelector((state) => state.authReducer.user);
    var users = Storage.getString(storageKeys.USER);
    let userUse = user ? user : users

    const isMarketing = () => userUse.role === "field_marketing";

    function checkHeader(data, isHeader) {
        const eventSubmitsHearder = [
            {
                "label": "First Name",
                "questionType": "text",
                "key": "first_name",
                "isRequired": true,
                "answers": []
            },
            {
                "label": "Last Name",
                "questionType": "text",
                "key": "last_name",
                "isRequired": true,
                "answers": []
            },
            {
                "label": "Email Address",
                "questionType": "text",
                "key": "email",
                "isRequired": true,
                "answers": []
            },
            {
                "label": "Phone Number",
                "questionType": "text",
                "key": "phone_number",
                "isRequired": true,
                "answers": []
            },
        ]

        let newHeader = data && data.map(it => ({
            "label": it?.label,
            "questionType": it?.questionType,
            "key": it?.key,
            "isRequired": it?.key ? true : false,
            "answers": it?.answers ? it?.answers : []
        }))

        return isHeader ? newHeader : eventSubmitsHearder
    }

    const initHeader = (dataItems) => {
        if (dataItems) {
            const newHeader = checkHeader(dataItems.eventSubmits, dataItems.eventSubmits);

            const temp = [];
            newHeader.map((item) => {
                temp.push({
                    title: item.key === "agreement" ? <span style={{ textWrap: "nowrap" }}>Agree to the conditions</span> : <span>{item.label}</span>,
                    width: 450,
                    dataIndex: item.key,
                    render: (_, record) => {
                        if (!record || !record.eventSubmits) {
                            return "";
                        }

                        const currentKey = record.eventSubmits.find(i => i.key === item.key);
                        if (!currentKey) {
                            return "";
                        }

                        if (!currentKey.isRequired && !currentKey.answers) {
                            return "";
                        }

                        if (currentKey.questionType === "text") {
                            return <div>
                                {currentKey.answers.map(i => <div>{i}</div>)}
                            </div>
                        } else if (currentKey.questionType === "single-choice") {
                            return currentKey.answers.map(i => i.isanswer ? <div>{i.answer}</div> : "")
                        } else if (currentKey.questionType === "multi-choice") {
                            return <div>
                                {currentKey.answers.map(i => i.isanswer ? <div>{i.answer}</div> : "")}
                            </div>
                        }
                    }
                });
            })
            if (temp.length > 0) {
                if (isMarketing()) {
                    temp.push({
                        title: 'Submission time',
                        dataIndex: 'createdAt',
                        sorter: true,
                        render: (_, record) => formatDateTimeSlasMDYA(record.createdAt),
                    });
                }

                setColumns([...temp]);
            }
        }
    }

    const getCurrentWinner = (data) => {
        const winner = data.filter(i => !i.winnerHasRemoved);
        if (winner.length > 0) {
            return winner[0];
        }
        return null;
    }

    const fetchWinner = async () => {
        setListLoading(true);
        try {
            const res = await API.get(`${EVENT_WINNER}/${eventId}`)
            if (res.status === 200) {
                if (res.totalCount > 0) {
                    const winner = getCurrentWinner(res.data);
                    if (winner !== null) {
                        initHeader(winner)
                        setWinner({ ...winner });
                        setNoWinner(false);
                    }
                } else {
                    setNoWinner(true);
                }
            } else {
                messageModalRef.current.showWarning(res.message);
            }
        } catch (error) {
            messageModalRef.current.showError(error.message)
        } finally {
            setListLoading(false)
        }
    }

    const handleRandomWinner = async () => {
        setListLoading(true);
        try {
            const res = await API.post(EVENT_RANDOM_WINNER, { "eventId": eventId })
            if (res.status === 1) {
                const winner = getCurrentWinner(res.data);
                if (winner !== null) {
                    initHeader(winner)
                    setWinner({ ...winner });
                    setNoWinner(false);
                } 
            } else {
                messageModalRef.current.showError(res.message)
            }
        } catch (error) {
            messageModalRef.current.showError(error.message)
        } finally {
            setListLoading(false)
        }
    }

    const handleCancelWinner = async () => {
        messageModalRef.current.showWarningConfirm('Are you sure you want to remove this winner?', async () => {
            try {
                setListLoading(true)
                const res = await API.put(`${EVENT_REMOVE_WINNER}/${eventId}`, { "formId": winner.formId })
                if (res.status === 1) {
                    setNoWinner(true);
                    setWinner({ ...defaultKey });
                } else {
                    messageModalRef.current.showWarning(res.message);
                }
            } catch (error) {
                messageModalRef.current.showError(error.message)
            } finally {
                setListLoading(false)
            }
        }, "", true);
    }

    useEffect(() => {
        if (eventId) {
            fetchWinner()
        }
    }, [eventId])

    useImperativeHandle(ref, () => ({
        fetchWinner,
        handleCancelWinner,
        handleRandomWinner
    }), [fetchWinner, handleCancelWinner, handleRandomWinner]);

    return (
        <div className="w-100 ps-3 pe-3 pt-2" ref={winnerRef}>
            <div className="d-flex gap-3 align-items-center">
                <h5 style={{ fontWeight: "600", margin: "0", padding: 0 }}>Winner</h5>
                <Button disabled={listLoading || !noWinner} onClick={handleRandomWinner} variant="primary" className="btn-cus" size="sm" style={{ background: "none", borderColor: (noWinner ? "rgb(33, 194, 115)" : "rgb(149, 163, 187)"), color: (noWinner ? "rgb(33, 194, 115)" : "rgb(149, 163, 187)") }}>Random Winner</Button>
                {isMarketing() && <Button disabled={listLoading || noWinner} onClick={handleCancelWinner} variant="primary" size="sm" style={{ background: "none", borderColor: (!noWinner ? "#f87171" : "rgb(149, 163, 187)"), color: (!noWinner ? "#f87171" : "rgb(149, 163, 187)") }}>Remove Winner</Button>}
            </div>
            <div className='mt-3'>
                <Table
                    loading={listLoading}
                    columns={columns}
                    dataSource={winner._id !== "" ? [winner] : []}
                />
            </div>
            <MessageModalMain textCentered ref={messageModalRef} />
        </div>
    );
});

export default ChooseWinnerComponent;