import React from 'react'
import { SiTicktick } from "react-icons/si";
import { IoIosCheckboxOutline } from "react-icons/io";
import { IoMdCheckboxOutline } from "react-icons/io";

const AgreementIcon = (props) => {
    const { width, height } = props;
    return (
        <div className='d-flex align-items-center justify-content-center' style={{ width: width, height: height, backgroundColor: '#e6f0dd', borderRadius: 100 }}>
            <IoMdCheckboxOutline color='#95cf61' size="30px" />
        </div>
    )
}

export default AgreementIcon