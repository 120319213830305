import React from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";

export default function FormSuccess() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
        style={{ flex: 1 }}
    >
      <div>
        <div className="text_content container">
          <IoIosCheckmarkCircle style={{ fontSize: "160px", color: "#26b887" }} />
          <p id="pagenotfound">SUCCESS!</p>
          <p id="sorrynotfound">Your form has been submitted</p>
        </div>
      </div>
    </div>
  );
}
